const chatRoomRoutes = [
    {
        path: '/chatroom',
        name: 'chatroom',
        children: [
            {
                path: '',
                name: 'chatRoomPortalList',
                component: () => import('@/modules/chat_room/list/List.vue'),
                meta: {
                    resource: 'chat_channel',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Chatroom',
                    breadcrumbs: [{ title: 'Chatroom List', routeName: 'chatRoomPortalList' }]
                }
            },
            {
                path: 'new',
                name: 'chatRoomNew',
                component: () => import('@/modules/chat_room/entry/New.vue'),
                meta: {
                    resource: 'chat_channel',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Chatroom',
                    breadcrumbs: [
                        { title: 'Chatroom List', routeName: 'chatRoomNew' },
                        { title: 'New', routeName: 'chatRoomNew' }
                    ]
                }
            },
            {
                path: ':id/edit',
                name: 'chatRoomEdit',
                component: () => import('@/modules/chat_room/entry/Edit.vue'),
                meta: {
                    resource: 'chat_channel',
                    action: 'edit',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Chatroom',
                    breadcrumbs: [
                        { title: 'Chatroom List', routeName: 'chatRoomPortalList' },
                        { title: 'Edit', routeName: 'chatRoomEdit' }
                    ]
                }
            }
        ]
    }
]

export default chatRoomRoutes
