const moduleRoutes = [
  {
    path: "/pharmacy",
    name: "pharmacies",
    children: [
      {
        path: "",
        name: "pharmacyList",
        component: () => import("@/modules/pharmacy/list/List.vue"),
        meta: {
          resource: "pharmacies",
          action: "view",
          layout: "default",
          auth: true,
          title: "Pharmacies",
          breadcrumbs: [{ title: "Pharmacy List", routeName: "pharmacyList" }]
        }
      }
    ]
  }
]

export default moduleRoutes
