<template>
    <div>
        <component :is="layout">
            <router-view />
        </component>
    </div>

    <Toast position="top-right"></Toast>

    <Toast position="bottom-right" group="supportChat" :closable="true" class="p-custom-toast">
        <template #message="slotProps">
            <div class="flex flex-column w-full">
                <div class="text-center">
                    <div class="mt-3">
                        <i class="ri ri-message-2-line" style="font-size: 3rem"></i>
                    </div>
                    <div class="ml-3 mt-2 mr-6">
                        <a>{{ slotProps.message.detail }}</a>
                        <div class="mt-2">
                            <i class="pi pi-arrow-right mr-1" /><a href="/doctor-chat">{{ slotProps.message.summary }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Toast>

    <Toast position="bottom-right" group="bc" :closable="true" class="p-custom-toast p-custom-close-icon">
        <template #message="slotProps">
            <div class="flex flex-column w-full">
                <div class="text-center">
                    <h4 class="mb-0">{{ slotProps.message.name }}</h4>
                    <div>is calling you</div>
                    <Avatar :image="slotProps.message.avatar" size="xlarge" class="my-avatar my-5" />
                </div>
                <div class="grid p-fluid">
                    <div class="col-6">
                        <Button class="p-button-danger" label="Decline" @click="onReject(slotProps.message.appointmentId)"></Button>
                    </div>
                    <div class="col-6">
                        <Button class="p-button-success" label="Accept" @click="onConfirm(slotProps.message.id, slotProps.message.chatId, slotProps.message.appointmentId)"></Button>
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ScrollTop :threshold="200" class="custom-scrolltop" />
</template>

<script>
import EventBus from '@/libs/AppEventBus'
import LayoutDefault from '@/layouts/default/App.vue'
import LayoutCms from '@/layouts/cms/App.vue'
import Toast from 'primevue/toast'
import Button from 'primevue/button'
import ScrollTop from 'primevue/scrolltop'
import Avatar from 'primevue/avatar'
import { db } from '@/libs/firebase'
import { globalService } from './service/globalService'
import { useChatCountStore } from '@/store/chatCountStore'

export default {
    themeChangeListener: null,
    components: { Avatar, LayoutDefault, LayoutCms, Toast, ScrollTop, Button },
    computed: {
        layout() {
            if (this.$route.meta.layout === 'default') return 'layout-default'
            if (this.$route.meta.layout === 'cms') return 'layout-cms'
            return 'layout-full'
        }
    },

    data() {
        return {
            audio: new Audio(require('@/assets/sounds/ringtone.mp3')),
            supportChatAudio: new Audio(require('@/assets/sounds/support-chat.mp3')),
            user: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : 'undefined',
            service: globalService
        }
    },

    mounted() {
        const store = useChatCountStore()
        if (this.user !== 'undefined' && this.user.role == 'doctor' && this.user.doctor.support_chat_enable) {
            db.collection('chat_queues')
                .where('status', '==', 'pending')
                .onSnapshot(
                    (onSnapshot) => {
                        if (onSnapshot.size > 0) {
                            this.supportChatToast({
                                severity: 'info',
                                summary: 'Go to Chat',
                                detail: 'You have ' + onSnapshot.size + ' chat notification !'
                            })
                            this.newSupportChat(this.user.id)
                            store.setChatCount(onSnapshot.size)
                            // localStorage.setItem('chatCount', onSnapshot.size)
                        } else {
                            // localStorage.setItem('chatCount', 0)
                            store.setChatCount(0)
                        }
                    },
                    (err) => {
                        store.setChatCount(0)
                        console.log(`Encountered error: ${err}`)
                        // localStorage.setItem('chatCount', 0)
                    }
                )
        }

        if (this.$appState.darkTheme) {
            const elementId = 'theme-link'
            const linkElement = document.getElementById(elementId)
            const cloneLinkElement = linkElement.cloneNode(true)
            const newThemeUrl = linkElement.getAttribute('href').replace('lara-light-indigo', this.$appState.theme)

            cloneLinkElement.setAttribute('id', elementId + '-clone')
            cloneLinkElement.setAttribute('href', newThemeUrl)
            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove()
                cloneLinkElement.setAttribute('id', elementId)
            })
            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling)
        }

        this.themeChangeListener = (event) => {
            const elementId = 'theme-link'
            const linkElement = document.getElementById(elementId)
            const cloneLinkElement = linkElement.cloneNode(true)
            const newThemeUrl = linkElement.getAttribute('href').replace(this.$appState.theme, event.theme)

            cloneLinkElement.setAttribute('id', elementId + '-clone')
            cloneLinkElement.setAttribute('href', newThemeUrl)
            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove()
                cloneLinkElement.setAttribute('id', elementId)
            })
            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling)

            this.$appState.theme = event.theme
            this.$appState.darkTheme = event.dark
        }

        EventBus.on('theme-change', this.themeChangeListener)
        EventBus.on('show-toast', this.showToast)
        EventBus.on('show-call-notification', this.showCallNotification)
        // EventBus.on('healthnews-toast', this.healthnewsToast)
        EventBus.on('support-chat-toast', this.supportChatToast)
        EventBus.on('call-hung-up', this.hangup)
        EventBus.on('doctor-in-call', this.doctorInCall)
        EventBus.on('hung-up', this.showHangUpToast)
    },
    methods: {
        showToast(event) {
            this.$toast.add({
                severity: event.severity,
                summary: event.summary,
                detail: event.detail,
                life: 3000
            })
        },

        showHangUpToast(event) {
            this.$toast.removeGroup('hangup')
            this.$toast.add({
                severity: event.severity,
                summary: event.summary,
                detail: event.detail,
                life: 3000,
                group: 'hangup'
            })
        },

        healthnewsToast(event) {
            //this.playNotiSong()
            this.$toast.removeGroup('healthnews')
            this.$toast.add({
                severity: event.severity,
                summary: event.summary,
                detail: event.detail,
                life: 3000,
                group: 'healthnews'
            })
        },

        supportChatToast(event) {
            this.playNotiSong('supportChat')
            this.$toast.removeGroup('supportChat')
            this.$toast.add({
                severity: event.severity,
                summary: event.summary,
                detail: event.detail,
                group: 'supportChat',
                life: 60000
            })
        },

        showCallNotification(event) {
            this.playNotiSong()
            this.$toast.removeGroup('bc')
            this.$toast.add({
                severity: '',
                avatar: event.avatar,
                name: event.name,
                id: event.id,
                chatId: event.chatId,
                appointmentId: event.appointmentId,
                group: 'bc',
                life: 60000
            })
        },
        async onConfirm(id, chatId, appointmentId) {
            await this.service.getDoctorCallAction({
                action: true,
                appointmentId: appointmentId
            })
            this.pauseNotiSong()
            this.$toast.removeGroup('bc')
            this.$router.push({
                name: 'videoChatDetail',
                params: { room: id, chat_id: chatId, appointment_id: appointmentId },
                query: { incoming: true }
            })
        },
        async onReject(appointmentId) {
            let response = await this.service.getDoctorCallAction({
                action: false,
                appointmentId: appointmentId
            })
            console.log(response)
            this.pauseNotiSong()
            this.$toast.removeGroup('bc')
        },
        async doctorInCall(e) {
            this.onReject(e.appointmentId)
        },
        async newSupportChat(userId) {
            await this.service.newSupportChat({
                user_id: userId
            })
        },
        playNotiSong(type = null) {
            if (type == 'supportChat') {
                this.supportChatAudio.play()
            } else {
                this.audio.play()
            }
        },
        pauseNotiSong() {
            this.audio.pause()
        },
        hangup() {
            this.audio.pause()
            this.$toast.removeGroup('bc')
        }
    },
    beforeUnmount() {
        EventBus.off('theme-change', this.themeChangeListener)
        EventBus.off('show-toast', this.showToast)
        // EventBus.off('healthnews-toast', this.healthnewsToast)
        EventBus.off('support-chat-toast', this.supportChatToast)
        EventBus.off('show-call-notification', this.showCallNotification)
        EventBus.off('call-hung-up', this.hangup)
        EventBus.off('hung-up', this.showHangUpToast)
    }
}
</script>

<style lang="scss">
.p-scroll-top.p-link {
    background: var(--primary-color);
}
.p-custom-toast .p-toast-message {
    background: white;
}
.p-custom-close-icon .p-toast-icon-close {
    display: none !important;
}
.my-avatar img {
    border-radius: 1rem;
}
</style>
