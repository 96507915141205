const moduleRoutes = [
  {
    path: "/package",
    name: "packages",
    children: [
      {
        path: "",
        name: "packageList",
        component: () => import("@/modules/package/list/List.vue"),
        meta: {
          resource: "packages",
          action: "view",
          layout: "default",
          auth: true,
          title: "Packages",
          breadcrumbs: [{ title: "Package List", routeName: "packageList" }]
        }
      },
      {
        path: "new",
        name: "packageNew",
        component: () => import("@/modules/package/entry/New.vue"),
        meta: {
          resource: "packages",
          action: "create",
          layout: "default",
          auth: true,
          title: "New Package",
          breadcrumbs: [
            { title: "Package List", routeName: "packageList" },
            { title: "New", routeName: "packageNew" }
          ]
        }
      },
      {
        path: ":id/edit",
        name: "packageEdit",
        component: () => import("@/modules/package/entry/Edit.vue"),
        meta: {
          resource: "packages",
          action: "edit",
          layout: "default",
          auth: true,
          title: "Edit Package",
          breadcrumbs: [
            { title: "Package List", routeName: "packageList" },
            { title: "Edit", routeName: "packageEdit" }
          ]
        }
      }
    ]
  }
]

export default moduleRoutes
