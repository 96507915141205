const moduleRoutes = [
    {
        path: '/doctor-chat',
        name: 'doctorChat',
        component: () => import('@/modules/doctor_chat/doctorChat.vue'),
        meta: {
            resource: 'doctor-chat',
            action: 'view',
            layout: 'default',
            auth: true,
            title: 'Chat with Patients',
            breadcrumbs: []
        }
    },
    {
        path: '/doctor-chat/:id',
        name: 'doctorEditChat',
        component: () => import('@/modules/doctor_chat/doctorChat.vue'),
        meta: {
            resource: 'doctor-chat',
            action: 'view',
            layout: 'default',
            auth: true,
            title: 'Chat with Patients',
            breadcrumbs: []
        }
    }
]

export default moduleRoutes
