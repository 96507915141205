const moduleRoutes = [
    {
        path: '/referrals',
        name: 'referrals',
        children: [
            {
                path: '',
                name: 'referralsList',
                component: () => import('@/modules/referral/list/List.vue'),
                meta: {
                    resource: 'referrals',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Referral List',
                    breadcrumbs: [{ title: 'Referral List', routeName: 'referralsList' }]
                }
            }
        ]
    }
]

export default moduleRoutes
