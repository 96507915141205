import api from "@/libs/axios"

const endpoint = "/notifications"

const service = {
  getAll: async (params) => {
    try {
      const result = await api.get(endpoint, {
        params: params
      })
      return result.data
    } catch {
      return null
    }
  },

  check: async (params) => {
    try {
      const result = await api.post(`${endpoint}/${params.id}/check`)
      return result.data
    } catch {
      return null
    }
  },

  delete: async (params) => {
    try {
      const result = await api.delete(`${endpoint}/${params.id}/delete`)
      return result.data
    } catch {
      return null
    }
  },

  markAsAll: async () => {
    try {
      const result = await api.post(`${endpoint}/mark-as-all`)
      return result.data
    } catch {
      return null
    }
  }
}

export { service }
