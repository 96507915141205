import ContentList from "@/modules/content/list/List"
import NewContent from "@/modules/content/entry/New"
import EditContent from "@/modules/content/entry/Edit"

const routes = [
  {
    path: "/content",
    name: "cms-contents",
    children: [
      {
        path: "",
        name: "contentList",
        component: ContentList,
        meta: {
          layout: "default",
          action: "view",
          resource: "content",
          title: "Contents",
          breadcrumbs: [{ title: "Content List", routeName: "contentList" }]
        }
      },
      {
        path: "new",
        name: "newContent",
        component: NewContent,
        meta: {
          layout: "default",
          action: "create",
          resource: "content",
          title: "Create Content",
          breadcrumbs: [
            { title: "Content List", routeName: "contentList" },
            { title: "Create", routeName: "newContent" }
          ]
        }
      },
      {
        path: ":id/edit",
        name: "editContent",
        component: EditContent,
        meta: {
          layout: "default",
          action: "update",
          resource: "content",
          title: "Edit Content",
          breadcrumbs: [
            { title: "Content List", routeName: "contentList" },
            { title: "Edit", routeName: "editContent" }
          ]
        }
      }
    ]
  }
]

export default routes
