const moduleRoutes = [
  {
    path: "/corporate",
    name: "corporates",
    children: [
      {
        path: "",
        name: "corporateList",
        component: () => import("@/modules/corporate/list/List.vue"),
        meta: {
          resource: "corporates",
          action: "view",
          layout: "default",
          auth: true,
          title: "Corporates",
          breadcrumbs: [{ title: "Corporate List", routeName: "corporateList" }]
        }
      },
      {
        path: "new",
        name: "corporateNew",
        component: () => import("@/modules/corporate/entry/New.vue"),
        meta: {
          resource: "corporates",
          action: "create",
          layout: "default",
          auth: true,
          title: "New Corporate",
          breadcrumbs: [
            { title: "Corporate List", routeName: "corporateList" },
            { title: "New", routeName: "corporateNew" }
          ]
        }
      },
      {
        path: ":id/edit",
        name: "corporateEdit",
        component: () => import("@/modules/corporate/entry/Edit.vue"),
        meta: {
          resource: "corporates",
          action: "edit",
          layout: "default",
          auth: true,
          title: "Edit Corporate",
          breadcrumbs: [
            { title: "Corporate List", routeName: "corporateList" },
            { title: "Edit", routeName: "corporateEdit" }
          ]
        }
      },
      {
        path: ":id/:name/employeelist",
        name: "corporateEmployees",
        component: () => import("@/modules/patient/list/List.vue"),
        props: true,
        meta: {
          resource: "patients",
          action: "view",
          layout: "default",
          auth: true,
          title: "Employees",
          breadcrumbs: [
            { title: "Corporate List", routeName: "corporateList" },
            { title: "Employees List", routeName: "corporateEmployees" }
          ]
        }
      }
    ]
  }
]

export default moduleRoutes
