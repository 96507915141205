import { defineStore } from "pinia"
import { service } from "./service"

export const useStore = defineStore({
  id: "useCategoryStore",
  state: () => ({
    listResponse: null,
    oneResponse: null,
    updateResponse: null,
    addResponse: null,
    deleteResponse: null,
    listContentResponse: null
  }),

  getters: {
    getAllResponse(state) {
      return state.listResponse
    },
    getAllContentResponse(state) {
      return state.listContentResponse
    },
    getOneResponse(state) {
      return state.detailResponse
    },
    getAddResponse(state) {
      return state.addResponse
    },
    getUpdateResponse(state) {
      return state.updateResponse
    },
    getDeleteResponse(state) {
      return state.deleteResponse
    }
  },

  actions: {
    async fetchAll(params) {
      const response = await service.getAll(params)
      this.listResponse = response
    },
    async fetchAllContentCategory(params) {
      const response = await service.getAllContentCategory(params)
      this.listContentResponse = response
    },
    async fetchOne(params) {
      const response = await service.getOne(params)
      this.detailResponse = response
    },
    async update(params) {
      const response = await service.update(params)
      this.updateResponse = response
    },
    async add(params) {
      const response = await service.add(params)
      this.addResponse = response
    },
    async delete(params) {
      const response = await service.delete(params)
      this.deleteResponse = response
    }
  }
})
