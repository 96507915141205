const mobileSettingRoutes = [
    {
        path: '/mobile-setting',
        name: 'mobile-settings',
        children: [
            {
                path: '',
                name: 'mobileSettingList',
                component: () => import('@/modules/mobile_setting/list/List.vue'),
                meta: {
                    resource: 'settings',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Mobile Settings',
                    breadcrumbs: [{ title: 'Mobile Setting List', routeName: 'mobileSettingList' }]
                }
            }
        ]
    }
]

export default mobileSettingRoutes
