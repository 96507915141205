const moduleRoutes = [
    {
        path: '/facility',
        name: 'facilities',
        children: [
            {
                path: '',
                name: 'facilityList',
                component: () => import('@/modules/facility/list/List.vue'),
                meta: {
                    resource: 'facilities',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Facilities',
                    breadcrumbs: [{ title: 'Facility List', routeName: 'facilityList' }]
                }
            },
            {
                path: 'new',
                name: 'facilityNew',
                component: () => import('@/modules/facility/entry/New.vue'),
                meta: {
                    resource: 'facilities',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Facility',
                    breadcrumbs: [
                        { title: 'Facility List', routeName: 'facilityList' },
                        { title: 'New', routeName: 'facilityNew' }
                    ]
                }
            },
            {
                path: ':id/edit',
                name: 'facilityEdit',
                component: () => import('@/modules/facility/entry/Edit.vue'),
                meta: {
                    resource: 'facilities',
                    action: 'edit',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Facility',
                    breadcrumbs: [
                        { title: 'Facility List', routeName: 'facilityList' },
                        { title: 'Edit', routeName: 'facilityEdit' }
                    ]
                }
            }
        ]
    },
    {
        path: '/facility-type',
        name: 'facilityTypes',
        children: [
            {
                path: '',
                name: 'facilityTypeList',
                component: () => import('@/modules/facility/facility_type/list/List.vue'),
                meta: {
                    resource: 'facilities',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Facility Type List',
                    breadcrumbs: [{ title: 'Facility Type List', routeName: 'facilityTypeList' }]
                }
            },
            {
                path: 'new',
                name: 'facilityTypeNew',
                component: () => import('@/modules/facility/facility_type/entry/New.vue'),
                meta: {
                    resource: 'facilities',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Facility Type',
                    breadcrumbs: [
                        { title: 'Facility Type List', routeName: 'facilityTypeList' },
                        { title: 'New', routeName: 'facilityTypeNew' }
                    ]
                }
            },
            {
                path: ':id/edit',
                name: 'facilityTypeEdit',
                component: () => import('@/modules/facility/facility_type/entry/Edit.vue'),
                meta: {
                    resource: 'facilities',
                    action: 'edit',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Facility Type',
                    breadcrumbs: [
                        { title: 'Facility Type List', routeName: 'facilityTypeList' },
                        { title: 'Edit', routeName: 'facilityTypeEdit' }
                    ]
                }
            }
        ]
    },
    {
        path: '/city',
        name: 'cities',
        children: [
            {
                path: '',
                name: 'citiesList',
                component: () => import('@/modules/facility/city_branch/list/List.vue'),
                meta: {
                    resource: 'facilities',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'City List',
                    breadcrumbs: [{ title: 'City List', routeName: 'citiesList' }]
                }
            },
            {
                path: 'new',
                name: 'citiesNew',
                component: () => import('@/modules/facility/city_branch/entry/New.vue'),
                meta: {
                    resource: 'facilities',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New City',
                    breadcrumbs: [
                        { title: 'City List', routeName: 'citiesList' },
                        { title: 'New', routeName: 'citiesNew' }
                    ]
                }
            },
            {
                path: ':id/edit',
                name: 'citiesEdit',
                component: () => import('@/modules/facility/city_branch/entry/Edit.vue'),
                meta: {
                    resource: 'facilities',
                    action: 'edit',
                    layout: 'default',
                    auth: true,
                    title: 'Edit City',
                    breadcrumbs: [
                        { title: 'City List', routeName: 'citiesList' },
                        { title: 'Edit', routeName: 'citiesEdit' }
                    ]
                }
            }
        ]
    }
]

export default moduleRoutes
