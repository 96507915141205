const moduleRoutes = [
    {
        path: '/user',
        name: 'users',
        children: [
            {
                path: '',
                name: 'userList',
                component: () => import('@/modules/user/list/List.vue'),
                meta: {
                    resource: 'users',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Users',
                    breadcrumbs: [{ title: 'User List', routeName: 'userList' }]
                }
            },
            {
                path: 'new',
                name: 'userNew',
                component: () => import('@/modules/user/entry/New.vue'),
                meta: {
                    resource: 'users',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New User',
                    breadcrumbs: [
                        { title: 'User List', routeName: 'userList' },
                        { title: 'New', routeName: 'userNew' }
                    ]
                }
            },
            {
                path: ':id/edit',
                name: 'userEdit',
                component: () => import('@/modules/user/entry/Edit.vue'),
                meta: {
                    resource: 'users',
                    action: 'edit',
                    layout: 'default',
                    auth: true,
                    title: 'Edit User',
                    breadcrumbs: [
                        { title: 'User List', routeName: 'userList' },
                        { title: 'Edit', routeName: 'userEdit' }
                    ]
                }
            }
        ]
    },

    {
        path: '/profile',
        name: 'userprofile',
        component: () => import('@/modules/user/list/Profile.vue'),
        meta: {
            resource: 'users',
            action: 'profile',
            layout: 'default',
            title: 'Profile',
            breadcrumbs: [{ title: 'User Profile', routeName: 'userprofile' }]
        }
    }
]

export default moduleRoutes
