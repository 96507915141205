import { useAuthStore } from "@/modules/auth/authStore"

export const getHomeRouteForLoggedInUser = () => {
  const authStore = useAuthStore()
  const userPermission = authStore.getUserPermission
    ? JSON.parse(authStore.getUserPermission)
    : null

  if (userPermission) {
    const index = userPermission.findIndex((item) => item.action == "view")
    if (index == -1) {
      return { path: "error" }
    }
    if (userPermission[index].route_name == "appointment") {
      const refundIndex = userPermission.findIndex(
        (item) => item.action == "view_refund"
      )
      const historyIndex = userPermission.findIndex(
        (item) => item.action == "view_history"
      )
      const currentIndex = userPermission.findIndex(
        (item) => item.action == "view_current"
      )

      if (currentIndex > -1) {
        return { path: "appointment/current" }
      }
      if (historyIndex > -1) {
        return { path: "appointment/history" }
      }
      if (refundIndex > -1) {
        return { path: "appointment/refund" }
      }
      if (index == -1) {
        return { path: "error" }
      }
    }
    return { path: userPermission[index].route_name }
  }
}
