import { defineStore } from 'pinia'

export const useChatCountStore = defineStore({
    id: 'useChatCountStore',
    state: () => ({
        chatCount: 0
    }),

    getters: {
        getChatCount(state) {
            return state.chatCount
        }
    },

    actions: {
        async setChatCount(count) {
            this.chatCount = count
        }
    }
})
