const familyMemberRoutes = [
    {
        path: '/family-members',
        name: 'familyMember',
        component: () => import('@/modules/family_member/list/List.vue'),
        meta: {
            resource: 'familymembers',
            action: 'view',
            layout: 'default',
            auth: true,
            title: 'Family Member',
            breadcrumbs: [{ title: 'Family Member', routeName: 'familyMember' }]
        }
    }
]

export default familyMemberRoutes
