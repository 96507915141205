import { defineStore } from "pinia"
import { service } from "./service"

export const useStore = defineStore({
  id: "useInstantChat",
  state: () => ({
    listAppointmentChatUserResponse: null
  }),

  getters: {
    getAppointmentChatUserResponse(state) {
      return state.listAppointmentChatUserResponse
    }
  },

  actions: {
    async fetchAppointmentChatUser(params) {
      const response = await service.getAppointmentChatUser(params)
      this.listAppointmentChatUserResponse = response
    }
  }
})
