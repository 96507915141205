const moduleRoutes = [
    {
        path: '/appointment',
        name: 'appointments',
        children: [
            {
                path: ':type',
                name: 'appointmentsList',
                component: () => import('@/modules/appointments/list/List.vue'),
                meta: {
                    resource: 'appointments',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Appointment List',
                    breadcrumbs: [{ title: 'Appointments List', routeName: 'appointmentsList' }]
                }
            },
            {
                path: '/appointment/new',
                name: 'appointmentNew',
                component: () => import('@/modules/appointments/entry/New.vue'),
                meta: {
                    resource: 'appointments',
                    action: 'make_appointment',
                    layout: 'default',
                    auth: true,
                    title: 'Make Appointment',
                    breadcrumbs: [{ title: 'New Appointment', routeName: 'appointmentNew' }]
                }
            }
        ]
    }
]

export default moduleRoutes
