const moduleRoutes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/modules/dashboard/Dashboard.vue'),
        meta: {
            resource: 'dashboard',
            action: 'view',
            auth: true,
            layout: 'default',
            title: 'Dashboard',
            breadcrumbs: []
        }
    },
    {
        path: '/',
        name: '',
        children: [
            {
                path: 'request-chat-list',
                name: 'requestchatList',
                component: () => import('@/modules/dashboard/ChatList.vue'),
                meta: {
                    resource: 'dashboard',
                    action: 'view_request_chat_count_card',
                    layout: 'default',
                    auth: true,
                    title: 'Request Chat List',
                    breadcrumbs: [{ title: 'Request Chat List', routeName: 'requestchatList' }]
                }
            },
            {
                path: 'complete-chat-list',
                name: 'completechatList',
                component: () => import('@/modules/dashboard/ChatList.vue'),
                meta: {
                    resource: 'dashboard',
                    action: 'view_completed_chat_count_card',
                    layout: 'default',
                    auth: true,
                    title: 'Completed Chat List',
                    breadcrumbs: [{ title: 'Completed Chat List', routeName: 'completechatList' }]
                }
            }
        ]
    }
]

export default moduleRoutes
