<template>
    <div class="flex justify-content-center align-items-center cursor-pointer ml-3" @click="togglePanel">
        <Avatar
            :image="authStore.getUserData ? authStore.getUserData.avatar : ''"
            size="large"
            shape="circle"
            class="border-2"
            :class="idledFor < tenMinutes ? 'border-green-500' : idledFor < fifthteenMinutes ? 'border-orange-500' : 'border-red-500'"
        />

        <div class="user-menu ml-4 hidden lg:block">
            <div class="font-bold">{{ authStore.getUserData ? authStore.getUserData.name : '' }}</div>
            <!-- <div class="text-sm mt-1">{{ user.role }}</div> -->
        </div>
        <i class="ri-arrow-down-s-line ri-lg ml-2 hidden lg:block" style="color: var(--primary-color)"></i>
    </div>
    <OverlayPanel ref="userPanel" :style="{ width: '300px' }">
        <div class="p-0 m-0">
            <div class="p-4 bg-primary border-round-top-md">
                <div class="text-white text-xl font-bold">{{ authStore.getUserData ? authStore.getUserData.name : '' }}</div>
                <div class="text-white mt-2">{{ authStore.getUserData ? authStore.getUserData.role : '' }}</div>
            </div>
            <!-- My Profile -->
            <router-link :to="{ name: 'userprofile' }" class="appearance-none">
                <div class="p-4 flex align-items-center cursor-pointer">
                    <i class="ri-user-line" style="font-size: 1.2rem"></i>
                    <div class="pl-4">
                        <div class="text-xl font-medium">My Profile</div>
                        <div class="mt-2 text-gray-500 text-sm">View personal profile details.</div>
                    </div>
                </div>
            </router-link>
            <Divider class="p-0 m-0" />
            <!-- Privacy Settings -->

            <div class="p-4 text-center">
                <Button label="Sign Out" @click="userLogout" class="p-button-outlined p-button-danger" />
            </div>

            <ConfirmDialog :showHeader="false">
                <template #message="slotProps">
                    <div class="text-center w-full">
                        <Button type="button" :icon="slotProps.message.icon" class="p-button-lg p-button-danger p-button-rounded p-button-outlined mt-4" />
                        <h4>{{ slotProps.message.message }}</h4>
                    </div>
                </template>
            </ConfirmDialog>
        </div>
    </OverlayPanel>
    <Dialog header="Warning" v-model:visible="isTenMinutesIdle" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" :modal="true">
        <p class="m-0">You are not active over {{ Math.floor(idledFor / 60) }} minutes. If you are not acitve until 12 Hours, your account will be logoutted automatically.</p>
        <template #footer> </template>
    </Dialog>
    <Dialog header="Warning" v-model:visible="isFifthteenMinutesIdle" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" :modal="true">
        <p class="m-0">
            You are not active over 12 Hours. So your account will be logoutted in
            {{ logoutTime }} s
        </p>
        <template #footer> </template>
    </Dialog>
</template>
<script>
import { ref, defineComponent, onMounted, onUnmounted, computed, watch } from 'vue'

import { useAuthStore } from '@/modules/auth/authStore'
import { useRouter } from 'vue-router'
import { useConfirm } from 'primevue/useconfirm'
import { useIdle, useTimestamp } from '@vueuse/core'
import { db } from '@/libs/firebase'
import { useStore } from '@/modules/doctor_chat/store'

import Avatar from 'primevue/avatar'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import OverlayPanel from 'primevue/overlaypanel'
import ConfirmDialog from 'primevue/confirmdialog'
import Dialog from 'primevue/dialog'

export default defineComponent({
    name: 'AppUserProfile',
    components: { Avatar, Button, Dialog, Divider, OverlayPanel, ConfirmDialog },
    setup() {
        const authStore = useAuthStore()
        const router = useRouter()
        const userPanel = ref()
        const confirm = useConfirm()

        const user = authStore.getUserData

        const tenMinutes = ref(10 * 60)
        const fifthteenMinutes = ref(12 * 60 * 60) //12 hours
        const logoutTime = ref(30)
        const interval = ref(null)

        const togglePanel = (event) => {
            userPanel.value.toggle(event)
        }

        const handleScroll = () => {
            if (userPanel.value) {
                userPanel.value.hide()
            }
        }

        onMounted(() => {
            window.addEventListener('scroll', handleScroll)
        })

        onUnmounted(() => {
            clearInterval(interval.value)
            window.addEventListener('scroll', handleScroll)
        })

        const showConfirmDialog = () => {
            confirm.require({
                message: 'Are you sure?',
                icon: 'ri-error-warning-line ri-lg',
                acceptLabel: 'Sign out',
                rejectLabel: 'Cancel',
                acceptClass: 'p-button-danger',
                rejectClass: 'p-button-secondary p-button-text',
                accept: () => {
                    userLogout()
                },
                reject: () => {
                    //callback to execute when corporate rejects the action
                },
                onHide: () => {
                    //Callback to execute when dialog is hidden
                }
            })
        }

        const answeredList = ref([])
        const chatStore = useStore()
        const userLogout = async () => {
            /*All current chat end process when doctor log out*/
            if (user.role === 'doctor') {
                await chatStore.fetchChatRoomList({
                    agent_id: user.id,
                    page: 1,
                    per_page: 100,
                    status: 'in-progress'
                })
                const response = await chatStore.getChatRoomList
                if (response) {
                    answeredList.value.push(...response.data.data)
                    answeredList.value.map(async (chatRoomList) => {
                        try {
                            await chatStore.changeChatRoomLogoutStatus({
                                id: chatRoomList.id
                            })
                        } catch (e) {
                            console.error('Error reading document: ', e)
                        }
                    })

                    answeredList.value.map(async (chatRoomList) => {
                        try {
                            await db.collection('doctor_chat').doc(chatRoomList.chat_room_id.toString()).set({ status: 'complete' })
                        } catch (e) {
                            console.error('Error reading document: ', e)
                        }
                    })
                }
            }
            window.Echo.leave('comm.' + user.id)
            window.Echo.leave('notification.' + user.id)
            window.Echo.leave('notification.portal')
            window.Echo.leave('appointment')
            await authStore.revokeCurrentJwtToken()
            await authStore.logout()
            router.push({ name: 'login' })
        }

        const { idle, lastActive } = useIdle(10000)

        const now = useTimestamp()

        const idledFor = computed(() => Math.floor((now.value - lastActive.value) / 1000))

        const isTenMinutesIdle = computed(() => {
            return idledFor.value >= tenMinutes.value && idledFor.value < fifthteenMinutes.value
        })

        const sound = require('@/assets/sounds/idle.wav')
        const playIdle = () => {
            let idleSound = new Audio(sound)
            idleSound.addEventListener('canplaythrough', () => {
                idleSound.play()
            })
        }

        const isFifthteenMinutesIdle = computed(() => {
            return idledFor.value >= fifthteenMinutes.value
        })

        const countDownTimer = () => {
            interval.value = setInterval(() => {
                if (logoutTime.value === 0) {
                    userLogout()
                } else {
                    logoutTime.value--
                }
            }, 1000)
        }

        watch([isTenMinutesIdle], () => {
            if (isTenMinutesIdle.value === true) {
                playIdle()
            }
        })

        watch([isFifthteenMinutesIdle], () => {
            if (isFifthteenMinutesIdle.value === true) {
                countDownTimer()
            }
        })

        watch([idle], () => {
            if (idle.value) {
                logoutTime.value = 30
                clearInterval(interval.value)
            }
        })

        return {
            user,
            userPanel,
            togglePanel,
            userLogout,
            showConfirmDialog,
            idle,
            idledFor,
            tenMinutes,
            fifthteenMinutes,
            isTenMinutesIdle,
            isFifthteenMinutesIdle,
            logoutTime,
            authStore
        }
    }
})
</script>
<style>
.user-menu {
    min-width: 100px;
}
.p-overlaypanel .p-overlaypanel-content {
    padding: 0 !important;
}
/* .p-dialog-footer {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
} */
</style>
