<template>
    <div class="custom-loading">
        <div class="custom-loading-content">
            <img :src="loadingImage()" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Loading',
    methods: {
        loadingImage() {
            return this.$appState.darkTheme ? require('@/assets/images/loading-heart-beat-white.svg') : require('@/assets/images/loading-heart-beat-blue.svg')
        }
    }
})
</script>

<style lang="scss" scoped>
.custom-loading {
    position: fixed;
    z-index: 999999;
    background: rgba(237, 241, 245, 0.6);
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.2s;
}
.custom-loading-content {
    width: 100px;
    height: 100px;
    position: absolute;
    top: calc(50vh - 75px);
    left: calc(50vw - 75px);
    //animation: spin 1.8s infinite linear;
}

.custom-loading-content img {
    display: block;
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
