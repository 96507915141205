const doctorAttendanceRoutes = [
  {
    path: "/doctor-attendance",
    name: "doctor-attendances",
    children: [
      {
        path: "",
        name: "doctorAttendanceList",
        component: () => import("@/modules/doctor_attendance/list/List.vue"),
        meta: {
          resource: "doctor-attendances",
          action: "view",
          layout: "default",
          auth: true,
          title: "Doctor Attendance",
          breadcrumbs: [
            { title: "Doctor Attendance", routeName: "doctorAttendanceList" }
          ]
        }
      }
    ]
  }
]

export default doctorAttendanceRoutes
