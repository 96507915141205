<template>
    <div class="layout-collapse-topbar">
        <button class="p-link layout-collapse-menu-button layout-collapse-topbar-button ml-0 lg:hidden" @click="onMenuToggle">
            <i class="ri-menu-line ri-lg" />
        </button>

        <AppBreadcrumb></AppBreadcrumb>

        <ul class="layout-collapse-topbar-menu flex origin-top">
            <li>
                <AppThemeToggle />
            </li>
            <li v-if="user.corporate_ids.length == 0">
                <AppNotification />
            </li>
            <li v-if="user.role == 'doctor'">
                <div class="flex align-items-center h-full px-3 cursor-pointer">
                    <i v-if="count == 0" class="ri-chat-4-line ri-lg text-primary" @click="toggleChat"></i>
                    <i v-else class="ri-chat-4-line ri-lg text-primary" v-badge.danger="count" @click="toggleChat"></i>
                </div>
            </li>
            <li>
                <AppUserProfile />
            </li>
        </ul>
    </div>
</template>

<script>
// import AppLocale from "./AppLocale.vue"
import AppThemeToggle from './AppThemeToggle.vue'
import AppUserProfile from './AppUserProfile.vue'
import AppNotification from '@/modules/notification/Notification.vue'
import AppBreadcrumb from './AppBreadcrumb.vue'
import { useAuthStore } from '@/modules/auth/authStore'
import useInstantChat from '@/modules/instant_chat/useInstantChat'
import { useRouter } from 'vue-router'

import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AppTopbar',
    components: {
        // AppLocale,
        AppThemeToggle,
        AppUserProfile,
        AppNotification,
        AppBreadcrumb
    },
    setup() {
        const authStore = useAuthStore()
        const user = authStore.getUserData
        const router = useRouter()

        const { count } = useInstantChat()

        //const isChatOpen = 0

        const toggleChat = () => {
            router.push({ name: 'appointmentChat' })
        }

        return {
            user,
            count,
            //isChatOpen
            toggleChat
        }
    },
    methods: {
        onMenuToggle(event) {
            // eslint-disable-next-line vue/require-explicit-emits
            this.$emit('menu-toggle', event)
        },
        toggleMenu(event) {
            this.$refs.menu.toggle(event)
        }
    }
})
</script>

<style lang="scss">
.chat-is-open {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    opacity: 0.1 !important;
}
</style>
