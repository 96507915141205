const packageGiftRoutes = [
    {
        path: '/package-gifts',
        name: 'packageGifts',
        children: [
            {
                path: '',
                name: 'packageGiftList',
                component: () => import('@/modules/package_gifts/list/List.vue'),
                meta: {
                    resource: 'transactions',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Package Gifts',
                    breadcrumbs: [{ title: 'Package Gift List', routeName: 'packageGiftList' }]
                }
            }
        ]
    }
]

export default packageGiftRoutes
