import api from '@/libs/axios'
import { firebaseAuth } from '@/libs/firebase'
import Cookies from 'js-cookie'

const endpoints = {
    login: '/auth/login',
    otpRequest: '/auth/otp/request',
    otpVerify: '/auth/otp/verify',
    changePassword: '/auth/change-password',
    forgotPassword: '/auth/forgot-password',
    firebaseCredential: '/firebase/credential',
    checkRole: '/auth/check-role',
    logOut: '/auth/logout'
}

const authService = {
    login: async (params) => {
        try {
            const result = await api.post(endpoints.login, {
                identifier: params.identifier,
                password: params.password,
                remember: params.isRemember
            })
            return result.data
        } catch {
            return null
        }
    },

    logout: () => {
        Cookies.remove('userToken')
        localStorage.removeItem('userData')
        localStorage.removeItem('userPermission')
        firebaseAuth.signOut()
    },

    changePassword: async (params) => {
        const result = await api.post(endpoints.changePassword, {
            current_password: params.current_password,
            password: params.password,
            password_confirmation: params.password_confirmation
        })
        return result.data
    },

    forgotPassword: async (params) => {
        const result = await api.post(endpoints.forgotPassword, {
            identifier: params.identifier
        })

        return result.data
    },

    getFirebaseCredential: async () => {
        try {
            const result = await api.get(endpoints.firebaseCredential)
            return result.data
        } catch {
            return null
        }
    },

    requestOTP: async (params) => {
        try {
            const result = await api.get(endpoints.otpRequest, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },

    otpVerify: async (params) => {
        try {
            const result = await api.post(endpoints.otpVerify, {
                type: params.type,
                phone_or_email: params.identifier,
                code: params.code
            })
            return result.data
        } catch {
            return null
        }
    },

    checkRole: async (params) => {
        try {
            const result = await api.post(endpoints.checkRole, {
                phone_or_email: params.phone_or_email
            })
            return result.data
        } catch {
            return null
        }
    },

    revokeCurrentJwtToken: async () => {
        try {
            const result = await api.post(endpoints.logOut, {})
            return result.data
        } catch {
            return null
        }
    }
}

export { authService }
