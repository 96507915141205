import { defineStore } from "pinia"
import { service } from "./service"

export const useStore = defineStore({
  id: "useNotificationStore",
  state: () => ({
    listResponse: null,
    checkResponse: null,
    deleteResponse: null,
    markAsAllResponse: null
  }),

  getters: {
    getAllResponse(state) {
      return state.listResponse
    },
    getCheckResponse(state) {
      return state.checkResponse
    },
    getDeleteResponse(state) {
      return state.deleteResponse
    },
    getMarkAsAllResponse(state) {
      return state.markAsAllResponse
    }
  },

  actions: {
    async fetchAll(params) {
      const response = await service.getAll(params)
      this.listResponse = response
    },
    async check(params) {
      const response = await service.check(params)
      this.checkResponse = response
    },
    async delete(params) {
      const response = await service.delete(params)
      this.deleteResponse = response
    },
    async markAsAll() {
      const response = await service.markAsAll()
      this.markAsAllResponse = response
    }
  }
})
