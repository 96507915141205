const couponRedemptionRoutes = [
    {
        path: '/coupon-redemption',
        name: 'couponRedemption',
        component: () => import('@/modules/coupon_redemption/list/List.vue'),
        meta: {
            resource: 'coupons',
            action: 'view',
            layout: 'default',
            auth: true,
            title: 'Coupon Redemption',
            breadcrumbs: [{ title: 'Coupon Redemption', routeName: 'couponRedemption' }]
        }
    }
]
export default couponRedemptionRoutes
