const moduleRoutes = [
    {
        path: '/teleconsultation-session',
        name: 'videoChat',
        children: [
            {
                path: ':room/:chat_id?/:appointment_id?',
                name: 'videoChatDetail',
                component: () => import('@/modules/video_chat/VideoChat.vue'),
                props: true,
                meta: {
                    action: 'view_chat',
                    resource: 'appointments',
                    subject: 'dashboard',
                    layout: 'default',
                    auth: true,
                    title: 'Teleconsultation Session'
                }
            }
        ]
    }
]

export default moduleRoutes
