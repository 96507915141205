const moduleRoutes = [
    {
        path: '/home-care-townships',
        name: 'homeCareTownships',
        children: [
            {
                path: '',
                name: 'homeCareTownshipList',
                component: () => import('@/modules/home_service_townships/list/List.vue'),
                meta: {
                    resource: 'home-care-townships',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Home Care Township',
                    breadcrumbs: [{ title: 'Home Care Township List', routeName: 'homeCareTownshipList' }]
                }
            },

            {
                path: ':id/edit',
                name: 'homeCareTownshipEdit',
                component: () => import('@/modules/home_service_townships/entry/Edit.vue'),
                meta: {
                    resource: 'home-care-townships',
                    action: 'show',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Home Care Townships',
                    breadcrumbs: [
                        { title: 'Home Care Township List', routeName: 'homeCareTownshipList' },
                        { title: 'Home Care Township Edit', routeName: 'homeCareTownshipEdit' }
                    ]
                }
            }
        ]
    }
]

export default moduleRoutes
