const authRoutes = [
    {
        path: '/kpaytest',
        name: 'kpaytest',
        component: () => import('./kpaytest/KpayTest'),
        meta: { redirectIfLoggedIn: true, title: 'KpayTest' }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./login/Login'),
        meta: { redirectIfLoggedIn: true, title: 'Login' }
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: () => import('./forgotPassword/forgotPassword'),
        meta: { redirectIfLoggedIn: true, title: 'Forgot Password' }
    }
]

export default authRoutes
