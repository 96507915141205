const moduleRoutes = [
  {
    path: "/symptom",
    name: "symptoms",
    children: [
      {
        path: "",
        name: "symptomList",
        component: () => import("@/modules/symptom/list/List.vue"),
        meta: {
          resource: "symptoms",
          action: "view",
          layout: "default",
          auth: true,
          title: "Symptoms",
          breadcrumbs: [{ title: "Symptom List", routeName: "symptomList" }]
        }
      },
      {
        path: "new",
        name: "symptomNew",
        component: () => import("@/modules/symptom/entry/New.vue"),
        meta: {
          resource: "symptoms",
          action: "create",
          layout: "default",
          auth: true,
          title: "New Symptom",
          breadcrumbs: [
            { title: "Symptom List", routeName: "symptomList" },
            { title: "New", routeName: "symptomNew" }
          ]
        }
      },
      {
        path: ":id/edit",
        name: "symptomEdit",
        component: () => import("@/modules/symptom/entry/Edit.vue"),
        meta: {
          resource: "symptoms",
          action: "edit",
          layout: "default",
          auth: true,
          title: "Edit Symptom",
          breadcrumbs: [
            { title: "Symptom List", routeName: "symptomList" },
            { title: "Edit", routeName: "symptomEdit" }
          ]
        }
      }
    ]
  }
]

export default moduleRoutes
