const moduleRoutes = [
    {
        path: '/notification',
        name: 'notification',
        children: [
            {
                path: '',
                name: 'notificationList',
                component: () => import('@/modules/custom_notification/list/List.vue'),
                meta: {
                    resource: 'custom-notification',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Custom Notification',
                    breadcrumbs: [{ title: 'Notification List', routeName: 'notificationList' }]
                }
            },
            {
                path: 'new',
                name: 'notificationNew',
                component: () => import('@/modules/custom_notification/entry/New.vue'),
                meta: {
                    resource: 'custom-notification',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Notification',
                    breadcrumbs: [
                        { title: 'Notification List', routeName: 'notificationList' },
                        { title: 'New', routeName: 'notificationNew' }
                    ]
                }
            },
            {
                path: ':id/edit',
                name: 'notificationEdit',
                component: () => import('@/modules/custom_notification/entry/Edit.vue'),
                meta: {
                    resource: 'custom-notification',
                    action: 'edit',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Notification',
                    breadcrumbs: [
                        { title: 'Notification List', routeName: 'notificationList' },
                        { title: 'Edit', routeName: 'notificationEdit' }
                    ]
                }
            }
        ]
    }
]

export default moduleRoutes
