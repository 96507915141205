const chatRoomRoutes = [
    {
        path: '/chat-subscriptions',
        name: 'chatSubscriptionList',
        children: [
            {
                path: '',
                name: 'chatSubscriptionList',
                component: () => import('@/modules/chat_subscriptions/list/List.vue'),
                meta: {
                    resource: 'chat_channel',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Chat Subscription',
                    breadcrumbs: [{ title: 'Chat Subscription List', routeName: 'chatSubscriptionList' }]
                }
            }
        ]
    }
]

export default chatRoomRoutes
