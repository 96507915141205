const moduleRoutes = [
  {
    path: "/category",
    name: "categories",
    children: [
      {
        path: "",
        name: "categoryList",
        component: () => import("@/modules/category/list/List.vue"),
        meta: {
          resource: "categories",
          action: "view",
          layout: "default",
          auth: true,
          title: "Categories",
          breadcrumbs: [{ title: "Category List", routeName: "categoryList" }]
        }
      },
      {
        path: "new",
        name: "categoryNew",
        component: () => import("@/modules/category/entry/New.vue"),
        meta: {
          resource: "categories",
          action: "create",
          layout: "default",
          auth: true,
          title: "New Category",
          breadcrumbs: [
            { title: "Category List", routeName: "categoryList" },
            { title: "New", routeName: "categoryNew" }
          ]
        }
      },
      {
        path: ":id/edit",
        name: "categoryEdit",
        component: () => import("@/modules/category/entry/Edit.vue"),
        meta: {
          resource: "categories",
          action: "edit",
          layout: "default",
          auth: true,
          title: "Edit Category",
          breadcrumbs: [
            { title: "Category List", routeName: "categoryList" },
            { title: "Edit", routeName: "categoryEdit" }
          ]
        }
      }
    ]
  }
]

export default moduleRoutes
