import { defineStore } from 'pinia'
import { service } from './service'

export const useStore = defineStore({
    id: 'useDoctorChatStore',
    state: () => ({
        uploadFileResponse: null,
        listResponse: null,
        addResponse: null,
        chatQueueStatus: true,
        currentUser: null,
        chatRoomData: null,
        chatRoomList: null,
        listClientResponse: null,
        chatRoomChangeStatusResponse: null,
        chatRoomChangeStatusLogoutResponse: null
    }),

    getters: {
        getUploadFileResponse(state) {
            return state.uploadFileResponse
        },
        getAllResponse(state) {
            return state.listResponse
        },

        getCreateNewChatRoomResponse(state) {
            return state.addResponse
        },

        getChatRoomDataResponse(state) {
            return state.chatRoomData
        },

        getCurrentChatUser(state) {
            return state.currentUser
        },

        getChatRoomList(state) {
            return state.chatRoomList
        },

        getClientUserList(state) {
            return state.listClientResponse
        },

        getChatRoomChangeStatus(state) {
            return state.chatRoomChangeStatusResponse
        },

        getChatRoomChangeLogoutStatus(state) {
            return state.chatRoomChangeStatusLogoutResponse
        }
    },

    actions: {
        async uploadFile(params) {
            const response = await service.uploadFile(params)
            this.uploadFileResponse = response
        },

        async fetchAll(params) {
            const response = await service.getAll(params)
            this.listResponse = response
        },

        async getClientUserData(params) {
            const response = await service.getClientUserData(params)
            this.listClientResponse = response
        },

        async changeChatQueueStatus(param) {
            await service.changeChatQueueStatus(param)
        },

        async fetchChatRoomList(params) {
            const response = await service.fetchChatRoomList(params)
            this.chatRoomList = response
        },

        async setCurrentUser(param) {
            this.currentUser = param
        },

        async createNewChatRoom(params) {
            const response = await service.createNewChatRoom(params)
            this.addResponse = response
        },

        async changeChatRoomStatus(params) {
            const response = await service.changeRoomStatus(params)
            this.chatRoomChangeStatusResponse = response
        },

        async changeChatRoomLogoutStatus(params) {
            this.chatRoomChangeStatusLogoutResponse = await service.changeRoomStatusLogout(params)
        },

        async fetchChatRoomDetail(params) {
            const response = await service.fetchChatRoomDetail(params)
            this.chatRoomData = response
        }
    }
})
