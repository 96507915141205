const moduleRoutes = [
    {
        path: '/hospital-service',
        name: 'hospital service',
        children: [
            {
                path: '',
                name: 'hospitalServiceList',
                component: () => import('@/modules/hospital_service/list/List.vue'),
                meta: {
                    resource: 'packages',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Hospital Service',
                    breadcrumbs: [{ title: 'Hospital Service', routeName: 'hospitalServiceList' }]
                }
            },

            {
                path: 'new',
                name: 'hospitalServiceNew',
                component: () => import('@/modules/hospital_service/entry/New.vue'),
                meta: {
                    resource: 'packages',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Hospital Service',
                    breadcrumbs: [{ title: 'New', routeName: 'hospitalServiceNew' }]
                }
            },

            {
                path: ':id',
                name: 'hospitalServiceEdit',
                component: () => import('@/modules/hospital_service/entry/Edit.vue'),
                meta: {
                    resource: 'packages',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Hospital Service',
                    breadcrumbs: [{ title: 'Edit', routeName: 'hospitalServiceEdit' }]
                }
            }
        ]
    }
]

export default moduleRoutes
