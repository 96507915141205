import { createRouter, createWebHistory } from 'vue-router'
import dashboardRoutes from '@/modules/dashboard/route'
import patientRoutes from '@/modules/patient/route'
import settingRoutes from '@/modules/setting/route'
import corporateRoutes from '@/modules/corporate/route'
import doctorRoutes from '@/modules/doctor/route'
import timeSlotRoutes from '@/modules/timeslot/route'
import userRoutes from '@/modules/user/route'
import symptomRoutes from '@/modules/symptom/route'
import specialitiesRoutes from '@/modules/specialities/route'
import appointmentRoutes from '@/modules/appointments/route'
import authRoutes from '@/modules/auth/authRoute'
import doctorChatRoutes from '@/modules/doctor_chat/route'
import videoChatRoutes from '@/modules/video_chat/route'
import { useAuthStore } from '@/modules/auth/authStore'
import { canNavigate } from '@/libs/acl/routeProtection'
import facilityRoutes from '@/modules/facility/route'
import doctorAttendanceRoutes from '@/modules/doctor_attendance/route'
import categoryRoutes from '@/modules/category/route'
import roleRoutes from '@/modules/role/route'
import serviceRoutes from '@/modules/service/route'
import townshipRoutes from '@/modules/township/route'
import contentRoutes from '@/modules/content/route'
import healthNewsRoutes from '@/modules/cms_healthnews/route'
import moduleRoutes from '@/modules/package/route'
import packagesaleRoutes from '@/modules/package_sale/route'
import packageusageRoutes from '@/modules/package_usage/route'
import referralRoutes from '@/modules/referral/route'
import forceUpdateRoutes from '@/modules/force_update/route'
import notificationsRoutes from '@/modules/custom_notification/route'
import hospitalServiceRoutes from '@/modules/hospital_service/route'
import promotionCenterRoutes from '@/modules/promotion_center/route'

// import Cookies from "js-cookie"
import pharmacyRoutes from '@/modules/pharmacy/route'
import { useLocaleStore } from '@/store/localeStore'
import { getHomeRouteForLoggedInUser } from '@/libs/acl/routeRedirect'
import couponRoutes from '@/modules/coupon/route'

import couponRedemptionRoutes from '@/modules/coupon_redemption/route'

import employeeRecordsRoutes from '@/modules/employee_records/route'
import instantChatRoutes from '@/modules/instant_chat/route'
import adsRoutes from '@/modules/ads/route'
import familyMemberRoutes from '@/modules/family_member/route'
import mobileSettingRoutes from '@/modules/mobile_setting/route'
import chatRoomRoutes from '@/modules/chat_room/route'
import chatSubscriptionRoutes from '@/modules/chat_subscriptions/route'
import packageGiftRoutes from '@/modules/package_gifts/route'
import homeServiceRoutes from '@/modules/home_service/route'
import homeServiceTownshipRoutes from '@/modules/home_service_townships/route'
//import i18n from "@/libs/i18n"
import auditLogRoutes from '@/modules/audit_log/route'

const routes = [
    ...doctorChatRoutes,
    ...contentRoutes,
    ...dashboardRoutes,
    ...authRoutes,
    ...userRoutes,
    ...patientRoutes,
    ...settingRoutes,
    ...doctorRoutes,
    ...timeSlotRoutes,
    ...symptomRoutes,
    ...specialitiesRoutes,
    ...corporateRoutes,
    ...facilityRoutes,
    ...appointmentRoutes,
    ...doctorAttendanceRoutes,
    ...videoChatRoutes,
    ...categoryRoutes,
    ...roleRoutes,
    ...serviceRoutes,
    ...townshipRoutes,
    ...healthNewsRoutes,
    ...pharmacyRoutes,
    ...referralRoutes,
    ...moduleRoutes,
    ...packagesaleRoutes,
    ...packageusageRoutes,
    ...couponRoutes,
    ...couponRedemptionRoutes,
    ...employeeRecordsRoutes,
    ...instantChatRoutes,
    ...adsRoutes,
    ...forceUpdateRoutes,
    ...familyMemberRoutes,
    ...notificationsRoutes,
    ...mobileSettingRoutes,
    ...chatRoomRoutes,
    ...chatSubscriptionRoutes,
    ...packageGiftRoutes,
    ...hospitalServiceRoutes,
    ...promotionCenterRoutes,
    ...homeServiceRoutes,
    ...auditLogRoutes,
    ...homeServiceTownshipRoutes,

    {
        path: '/error',
        name: 'error404',
        component: () => import('@/pages/NotFound.vue'),
        meta: {
            layout: 'full',
            title: '404 Not Found'
        }
    },
    {
        path: '/not-authorized',
        name: 'not-authorized',
        component: () => import('@/pages/Access.vue'),
        meta: {
            layout: 'full',
            title: 'No Authorized'
        }
    },
    {
        path: '/:catchAll(.*)*',
        redirect: 'error',
        meta: {
            layout: 'full',
            title: 'Error'
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'router-link-active',
    linkExactActiveClass: 'router-link-exact-active',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0, left: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    if (to.query.token) {
        localStorage.setItem('healthnewsToken', to.query.token)
    }

    if (to.query.lang) {
        const localeStore = useLocaleStore()
        localeStore.setLanguage(to.query.lang)
        //i18n.global.locale = to.query.lang
    }

    if (to.meta.title != 'undefined') {
        if (to.meta.title != null) {
            document.title = 'Heal - ' + to.meta.title
        }
    }
    window.scrollTo(0, 0)

    const authStore = useAuthStore()
    const isLoggedIn = authStore.isAuth

    if (to.meta.auth && !isLoggedIn) {
        return next({ name: 'login' })
    }

    if (!canNavigate(to)) {
        // Redirect to log in if not logged in
        if (!isLoggedIn) return next({ name: 'login' })

        // If logged in => not authorized
        return next({ name: 'not-authorized' })
    }
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        return next(getHomeRouteForLoggedInUser())
    }
    return next()
})

export default router
