export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပင်မ"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မင်္ဂလာပါ i18n !!"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မြန်မာ"])},
  "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပင်မစာမျက်နှာ"])},
  "user": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသုံးပြုသူစီမံခန့်ခွဲမှု"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသုံးပြုသူများ"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသုံးပြုသူအသစ်"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသုံးပြုသူပရိုဖိုင်"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["အသုံးပြုသူအချက်အလက်ပြောင်းလဲရန် ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသုံးပြုသူ‌အချက်အလက်မတွေ့ပါ။"])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသုံးပြုသူ‌အချက်အလက်များကိုရှာနေသည်။ ကျေးဇူးပြုပြီးခဏစောင့်ပါ။"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသုံးပြုသူခွင့်ပြုချက်"])}
  },
  "patient": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာစီမံခန့်ခွဲမှု"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာအချက်အလက်များ"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာအသစ်"])}
  },
  "doctor": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor List"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Doctor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Doctor"])}
  },
  "corporate": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကော်ပိုရိတ်စီမံခန့်ခွဲမှု"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကော်ပိုရိတ်‌အချက်အလက်များ"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကော်ပိုရိတ်အချက်အလက်အသစ်"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ကော်ပိုရိတ်အချက်အလက်ပြောင်းလဲရန် ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကော်ပိုရိတ်‌အချက်အလက်မတွေ့ပါ။"])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကော်ပိုရိတ်အချက်အလက်များကိုရှာနေသည်။ ကျေးဇူးပြုပြီးခဏစောင့်ပါ။"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကော်ပိုရိတ်အမည်"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကော်ပိုရိတ်အကြာင်း"])},
    "payment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကော်ပိုရိတ်ငွေပေးချေမှုအမျိုးအစား"])}
  },
  "setting": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စီမံခန့်ခွဲမှုကို သတ်မှတ်ခြင်း"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စီမံခန့်ခွဲမှုစာရင်းများ"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စီမံခန့်ခွဲမှုအသစ်"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["စီမံခန့်ခွဲမှုအချက်အလက်ပြောင်းလဲရန် ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စီမံခန့်ခွဲမှုစာရင်းမတွေ့ပါ။"])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စီမံခန့်ခွဲမှုစာရင်းများကိုရှာနေသည်။ ကျေးဇူးပြုပြီးခဏစောင့်ပါ။"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စီမံခန့်ခွဲမှုအမည်"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စီမံခန့်ခွဲမှုအကြာင်း"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စီမံခန့်ခွဲမှုတန်ဖိုး"])}
  },
  "symptom": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရောဂါလက္ခဏာ"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရောဂါလက္ခဏာစာရင်းများ"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရောဂါလက္ခဏာအသစ်"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ရောဂါလက္ခဏာအချက်အလက်ပြောင်းလဲရန် ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရောဂါလက္ခဏာစာရင်းမတွေ့ပါ။"])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရောဂါလက္ခဏာစာရင်းများကိုရှာနေသည်။ ကျေးဇူးပြုပြီးခဏစောင့်ပါ။"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရောဂါလက္ခဏာအမည်"])}
  },
  "chat": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat With Patients"])}
  },
  "specialities": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အထူးကုဘာသာရပ်"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အထူးကုဘာသာရပ်စာရင်း"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အထူးကုဘာသာရပ်စာရင်းအသစ်"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["အထူးကုဘာသာရပ်ပြင်ဆင်ချက် ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အထူးကုဘာသာရပ်စာရင်းမတွေ့ပါ"])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အထူးကုဘာသာရပ်စာရင်းရယူနေပါသည်. ခဏစောင့်ပါ"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialities Name"])}
  },
  "appointments": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointments"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointments List"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Appointment"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update Appointment ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Appointment found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Appointments data. Please wait."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Name"])}
  },
  "facility": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နေရာ"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New facility"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update facility ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No facility found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading facility data. Please wait."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Type"])}
  },
  "facilityType": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Type"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Type List"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Facility Type"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update Facility Type ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No facility type found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading facility type data. Please wait."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Type Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Description"])}
  },
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လိင်"])},
  "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဒီဂရီ"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြေအနေ"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်"])},
  "onlineOndemand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အွန်လိုင်းအမြန်တွေ့ဆုံ"])},
  "onlineBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အွန်လိုင်းစာရင်းသွင်း"])},
  "Inperson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကိုယ်တိုင်တွေ့ဆုံ"])},
  "videoChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဗီဒီယိုဖြင့်ဆွေးနွေး"])},
  "supportChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကူအညီရယူဆွေးနွေး"])},
  "timeSlotDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်းဖော်ပြ"])},
  "consultationFeeOnlineOndemand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အွန်လိုင်းအမြန်တွေ့ဆုံခြင်းစေ◌ျးနှုန်း"])},
  "consultationFeeOnlineBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အွန်လိုင်းစာရင်းသွင်းခြင်းစေ◌ျးနှုန်း"])},
  "biography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြောင်း"])},
  "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွေ့အကြုံ"])},
  "keywordsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စကားလုံးရှာဖွေမှု"])},
  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြုလုပ်ချိန်"])},
  "where_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဘယ်နေ့ ဘယ်ရက်လဲ"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရှာမယ်"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပယ်ဖျက်ပါ"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသစ်"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖန်တီးမည်"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြုပြင်မည်"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပယ်ဖျက်ပါ"])},
  "selected_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွေးချယ်ထားသည်များကိုဖျက်ပါ"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နာမည်"])},
  "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသုံးပြုသူအမည်"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အီးမေးလ်"])},
  "mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်"])},
  "mobile_country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နိုင်ငံကုဒ်"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လျှို့ဝှက်နံပါတ်"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စီမံခန့်ခွဲသူ"])},
  "corporate_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကော်ပိုရိတ်စီမံခန့်ခွဲသူ"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "today_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ယနေ့သတင်း"])},
  "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပရီမီယံ"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မျှဝေပါ"])}
}