const moduleRoutes = [
    {
        path: '/audit-log',
        name: 'audit-log',
        children: [
            {
                path: '',
                name: 'auditLogList',
                component: () => import('@/modules/audit_log/list/List.vue'),
                meta: {
                    resource: 'audit-logs',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Audit Log',
                    breadcrumbs: [{ title: 'Audit Log', routeName: 'auditLogList' }]
                }
            }
        ]
    }
]

export default moduleRoutes
