<template>
  <div class="flex justify-content-center">
    <div class="w-full md:w-30rem bg-white min-h-screen">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
