import { defineStore } from 'pinia'
import { useContentService } from '@/modules/content/service'

export const useContentStore = defineStore({
    id: 'useContentStore',
    state: () => ({
        listContentState: null,
        addContentState: null,
        editContentState: null,
        updateContentState: null,
        deleteContentState: null
    }),

    getters: {
        getAllContent(state) {
            return state.listContentState
        },

        getAddContent(state) {
            return state.addContentState
        },

        getEditContent(state) {
            return state.editContentState
        },

        getUpdateContent(state) {
            return state.updateContentState
        },

        getDeleteContent(state) {
            return state.deleteContentState
        }
    },

    actions: {
        async listContentAction(params) {
            const response = await useContentService.fetchAllContent(params)
            this.listContentState = response
        },

        async addContentAction(params) {
            const response = await useContentService.fechAddContent(params)
            this.addContentState = response
        },

        async editContentAction(params) {
            const response = await useContentService.fetchEditContent(params)
            this.editContentState = response
        },

        async updateContentAction(params) {
            const response = await useContentService.fetchUpdateContent(params)
            this.updateContentState = response
        },

        async deleteContentAction(params) {
            const response = await useContentService.fetchDeleteContent(params)
            this.deleteContentState = response
        }
    }
})
