import api from "@/libs/axios"
import qs from "qs"

const endpoint = "/categories"
const contentEndpoint = "/categories/content"
const service = {
  getAll: async (params) => {
    try {
      const result = await api.get(endpoint, {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params)
        }
      })

      return result.data
    } catch {
      return null
    }
  },

  getAllContentCategory: async (params) => {
    try {
      const result = await api.get(contentEndpoint, {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params)
        }
      })

      return result.data.data
    } catch {
      return null
    }
  },

  getOne: async (params) => {
    try {
      const result = await api.get(`${endpoint}/${params.id}/show`)
      return result.data
    } catch {
      return null
    }
  },
  update: async (params) => {
    const result = await api.put(`${endpoint}/${params.id}/update`, params)
    return result.data
  },
  add: async (params) => {
    const result = await api.post(`${endpoint}/create`, params)
    return result.data
  },
  delete: async (params) => {
    try {
      const result = await api.delete(`${endpoint}/${params.id}/delete`)
      return result.data
    } catch {
      return null
    }
  }
}

export { service }
