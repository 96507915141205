const moduleRoutes = [
  {
    path: "/township-setup",
    name: "townships",
    children: [
      {
        path: "",
        name: "townshipList",
        component: () => import("@/modules/township/list/List.vue"),
        meta: {
          resource: "township-setup",
          action: "view",
          layout: "default",
          auth: true,
          title: "Townships",
          breadcrumbs: [{ title: "Township List", routeName: "townshipList" }]
        }
      },
      {
        path: "new",
        name: "townshipNew",
        component: () => import("@/modules/township/entry/New.vue"),
        meta: {
          resource: "township-setup",
          action: "create",
          layout: "default",
          auth: true,
          title: "New Township",
          breadcrumbs: [
            { title: "Township List", routeName: "townshipList" },
            { title: "New", routeName: "townshipNew" }
          ]
        }
      },
      {
        path: ":id/update",
        name: "townshipEdit",
        component: () => import("@/modules/township/entry/Edit.vue"),
        meta: {
          resource: "township-setup",
          action: "edit",
          layout: "default",
          auth: true,
          title: "Edit Township",
          breadcrumbs: [
            { title: "Township List", routeName: "townshipList" },
            { title: "Edit", routeName: "townshipEdit" }
          ]
        }
      }
    ]
  }
]

export default moduleRoutes
