const moduleRoutes = [
  {
    path: "/service",
    name: "services",
    children: [
      {
        path: "",
        name: "serviceList",
        component: () => import("@/modules/service/list/List.vue"),
        meta: {
          resource: "services",
          action: "view",
          layout: "default",
          auth: true,
          title: "Services",
          breadcrumbs: [{ title: "Service List", routeName: "serviceList" }]
        }
      },
      {
        path: "new",
        name: "serviceNew",
        component: () => import("@/modules/service/entry/New.vue"),
        meta: {
          resource: "services",
          action: "create",
          layout: "default",
          auth: true,
          title: "New Service",
          breadcrumbs: [
            { title: "Service List", routeName: "serviceList" },
            { title: "New", routeName: "serviceNew" }
          ]
        }
      },
      {
        path: ":id/edit",
        name: "serviceEdit",
        component: () => import("@/modules/service/entry/Edit.vue"),
        meta: {
          resource: "services",
          action: "edit",
          layout: "default",
          auth: true,
          title: "Edit Service",
          breadcrumbs: [
            { title: "Service List", routeName: "serviceList" },
            { title: "Edit", routeName: "serviceEdit" }
          ]
        }
      }
    ]
  }
]

export default moduleRoutes
