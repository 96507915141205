import '@/plugins/global-css'
import { defineCustomElements } from '@ionic/pwa-elements/loader'

import { app } from '@/plugins/main-app'
import '@/plugins/abality'
import '@/plugins/confirmation-service'
import '@/plugins/global-components'
import '@/plugins/i18n'
import '@/plugins/pinia'
import '@/plugins/primevue'
import '@/plugins/router'
import '@/plugins/toast-service'
import '@/plugins/theme'
import '@/plugins/moment'
import '@/plugins/social'

app.mount('#heal-app')

defineCustomElements(window)
