const forceUpdateRoutes = [
    {
        path: '/force-update',
        name: 'forceUpdates',
        children: [
            {
                path: '',
                name: 'forceUpdate',
                component: () => import('@/modules/force_update/list/List.vue'),
                meta: {
                    resource: 'force_update',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Force Update',
                    breadcrumbs: [{ title: 'Force Update', routeName: 'forceUpdate' }]
                }
            },
            {
                path: 'new',
                name: 'forceUpdateNew',
                component: () => import('@/modules/force_update/entry/New.vue'),
                meta: {
                    resource: 'force_update',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Force Update',
                    breadcrumbs: [
                        { title: 'Force Update', routeName: 'forceUpdate' },
                        { title: 'New', routeName: 'forceUpdateNew' }
                    ]
                }
            },
            {
                path: ':id',
                name: 'forceUpdateEdit',
                component: () => import('@/modules/force_update/entry/Edit.vue'),
                meta: {
                    resource: 'force_update',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Force Update',
                    breadcrumbs: [
                        { title: 'Force Update', routeName: 'forceUpdate' },
                        { title: 'Edit', routeName: 'forceUpdateEdit' }
                    ]
                }
            }
        ]
    }
]

export default forceUpdateRoutes
