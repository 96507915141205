const instantChatRoutes = [
    {
        path: '/appointment-chat',
        name: 'appointmentChat',
        component: () => import('@/modules/instant_chat/InstantChat.vue'),
        meta: {
            // resource: "chat-appointments",
            // action: "",
            resource: 'appointments',
            action: 'view_chat',
            layout: 'default',
            auth: true,
            title: 'Appointment Chat',
            breadcrumbs: [{ title: 'Appointment Chat', routeName: 'appointmentChat' }]
        }
    }
]

export default instantChatRoutes
