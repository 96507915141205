const employeeRecordsRoutes = [
    {
        path: '/employee-record',
        name: 'employeeRecords',
        children: [
            {
                path: '',
                name: 'employeeRecordList',
                component: () => import('@/modules/employee_records/list/List.vue'),
                meta: {
                    resource: 'employee_records',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Employee Records',
                    breadcrumbs: [{ title: 'Employee Records', routeName: 'employeeRecords' }]
                }
            }
        ]
    }
]

export default employeeRecordsRoutes
