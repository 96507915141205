<template>
    <div :class="containerClass" @click="onWrapperClick">
        <div class="layout-collapse-sidebar" @click="onSidebarClick">
            <AppMenuHeader @menu-toggle="onMenuToggle" :menuActive="staticMenuInactive" :mobileMenuActive="mobileMenuActive" />
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-collapse-main-container">
            <Message v-if="has_message" severity="error">{{ message }}</Message>
            <div class="layout-collapse-main">
                <router-view />
                <!--                <router-view v-if="chatOpen == 0"/>-->
                <!--                <AppInstantChat v-if="chatOpen == 1 && user !== 'undefined' && user.role == 'doctor'"></AppInstantChat>-->
            </div>
            <AppFooter />
        </div>
        <transition name="layout-mask">
            <div v-if="mobileMenuActive" class="layout-mask p-component-overlay" />
        </transition>
    </div>
</template>

<script>
import EventBus from '@/libs/AppEventBus'
import AppTopBar from './AppTopbar.vue'
import AppMenu from './AppMenu.vue'
import AppMenuHeader from './AppMenuHeader.vue'
import AppFooter from './AppFooter.vue'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import menuList from './menu'
import { firebaseAuth } from '@/libs/firebase'
import { useAuthStore } from '@/modules/auth/authStore'
import Message from 'primevue/message'
// import Cookies from 'js-cookie'
// import AppInstantChat from "@/modules/instant_chat/InstantChat.vue"

export default {
    components: {
        AppTopBar: AppTopBar,
        AppMenu: AppMenu,
        AppFooter: AppFooter,
        AppMenuHeader: AppMenuHeader,
        Message: Message
        // AppInstantChat: AppInstantChat
    },
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            has_message: false,
            message: '',
            menu: menuList,
            home: {
                icon: 'pi pi-home',
                to: '/'
            },
            chatOpen: localStorage.getItem('chatOpen') ?? 0,
            user: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : 'undefined'
        }
    },
    mounted() {
        this.showChatOpen()
        EventBus.on('show-message', this.showMessage)
    },
    created() {
        window.Pusher = Pusher
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.VUE_APP_PUSHER_KEY,
            cluster: process.env.VUE_APP_PUSHER_CLUSTER,
            forceTLS: true,
            authEndpoint: process.env.VUE_APP_AUTH_ENDPOINT,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('userToken')
                }
            }
        })

        this.checkFirebaseAuth()
    },
    computed: {
        containerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-overlay': this.layoutMode === 'overlay',
                    'layout-static': this.layoutMode === 'static',
                    'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                    'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                    'layout-mobile-sidebar-active': this.mobileMenuActive,
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': this.$primevue.config.ripple === false
                }
            ]
        },
        logo() {
            return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo.svg'
        }
    },
    watch: {
        $route() {
            this.menuActive = false
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden')
        else this.removeClass(document.body, 'body-overflow-hidden')
    },
    methods: {
        showChatOpen: function () {
            setInterval(function () {
                this.chatOpen = localStorage.getItem('chatOpen') ?? 0
            }, 1000)
        },
        checkFirebaseAuth() {
            firebaseAuth.onAuthStateChanged((user) => {
                if (!user) {
                    const authStore = useAuthStore()
                    authStore.logout()
                }
            })
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false
                this.mobileMenuActive = false
            }

            this.menuClick = false
        },
        onMenuToggle() {
            this.menuClick = true

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true
                    }

                    this.overlayMenuActive = !this.overlayMenuActive
                    this.mobileMenuActive = false
                } else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive
                }
            } else {
                this.mobileMenuActive = !this.mobileMenuActive
            }

            event.preventDefault()
        },
        onSidebarClick() {
            this.menuClick = true
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false
                this.mobileMenuActive = false
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode
        },
        addClass(element, className) {
            if (element.classList) element.classList.add(className)
            else element.className += ' ' + className
        },
        removeClass(element, className) {
            if (element.classList) element.classList.remove(className)
            else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
        },
        isDesktop() {
            return window.innerWidth >= 992
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static') return !this.staticMenuInactive
                else if (this.layoutMode === 'overlay') return this.overlayMenuActive
            }

            return true
        },
        showMessage(event) {
            this.message = event.detail
            if (event.detail != null) this.has_message = true
            else this.has_message = false
        }
    },
    beforeUnmount() {
        EventBus.off('show-message', this.showMessage)
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
