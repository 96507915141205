const moduleRoutes = [
    {
        path: '/promotion-center',
        name: 'promotion center',
        children: [
            {
                path: '',
                name: 'promotionCenterList',
                component: () => import('@/modules/promotion_center/list/List.vue'),
                meta: {
                    resource: 'packages',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Promotion Center',
                    breadcrumbs: [{ title: 'Promotion Center List', routeName: 'promotionCenterList' }]
                }
            },
            {
                path: 'history',
                name: 'promotionHistoryList',
                component: () => import('@/modules/promotion_center/history_list/List.vue'),
                meta: {
                    resource: 'packages',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Promotion Center History',
                    breadcrumbs: [{ title: 'Promotion Center History List', routeName: 'promotionHistoryList' }]
                }
            }
        ]
    }
]

export default moduleRoutes
