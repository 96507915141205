<template>
  <div @click="toggle" :class="label.length > length ? 'cursor-pointer' : ''">
    {{ sliceString(label) }}
  </div>
  <OverlayPanel
    ref="tableLabelOverlay"
    appendTo="body"
    :showCloseIcon="false"
    id="overlay_panel"
    style="width: 300px"
    :breakpoints="{ '960px': '75vw' }"
  >
    <div class="p-4">
      {{ label }}
    </div>
  </OverlayPanel>
</template>
<script>
import { defineComponent, ref } from "vue"
import OverlayPanel from "primevue/overlaypanel"
export default defineComponent({
  components: { OverlayPanel },
  props: {
    label: {
      type: String,
      required: true
    },
    length: {
      type: Number,
      default: 50
    }
  },
  setup(props) {
    const tableLabelOverlay = ref()
    const toggle = (event) => {
      if (props.label.length > props.length) {
        tableLabelOverlay.value.toggle(event)
      }
    }

    const sliceString = (value) => {
      if (!value) return ""
      if (value.length > props.length) {
        return `${value.slice(0, props.length)}...`
      }
      return value
    }

    return {
      tableLabelOverlay,
      toggle,
      sliceString
    }
  }
})
</script>
<style lang=""></style>
