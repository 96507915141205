import ability from './ability'

export const canNavigate = (to) =>
    to.matched.some((route) => {
        if (route.meta.resource) {
            return ability.can(route.meta.action, route.meta.resource)
        } else if (route.path == '/login' || route.path == '/not-authorized' || route.path == '/forgot-password') {
            return true
        }
        return false
    })

export const _ = undefined
