const moduleRoutes = [
    {
        path: '/health-news',
        name: 'healthNews',
        children: [
            {
                path: '',
                name: 'healthNewsList',
                component: () => import('@/modules/cms_healthnews/list/List.vue'),
                meta: {
                    resource: 'doctors',
                    action: 'view',
                    layout: 'cms',
                    title: 'Health News List'
                }
            },
            {
                path: ':slug',
                name: 'healthNewsDetail',
                component: () => import('@/modules/cms_healthnews/entry/Detail.vue'),
                meta: {
                    resource: 'doctors',
                    action: 'view',
                    layout: 'cms',
                    title: 'Health News Detail'
                }
            },
            {
                path: 'bookmark',
                name: 'healthBookmark',
                component: () => import('@/modules/cms_healthnews/bookmark/Bookmark.vue'),
                meta: {
                    resource: 'dashboard',
                    action: 'view',
                    layout: 'cms',
                    title: 'Health Bookmark'
                }
            },
            {
                path: 'search',
                name: 'healthSearch',
                component: () => import('@/modules/cms_healthnews/search/Search.vue'),
                meta: {
                    layout: 'cms',
                    title: 'Health Search'
                }
            }
        ]
    }
]

export default moduleRoutes
