const moduleRoutes = [
  {
    path: "/roles-and-permission",
    name: "roles",
    children: [
      {
        path: "",
        name: "roleList",
        component: () => import("@/modules/role/list/List.vue"),
        meta: {
          resource: "roles-and-permissions",
          action: "manage",
          layout: "default",
          auth: true,
          title: "Role & Permission",
          breadcrumbs: [
            { title: "Role & Permission List", routeName: "roleList" }
          ]
        }
      },
      {
        path: "new",
        name: "roleNew",
        component: () => import("@/modules/role/entry/New.vue"),
        meta: {
          resource: "roles-and-permissions",
          action: "manage",
          layout: "default",
          auth: true,
          title: "New Role & Permission",
          breadcrumbs: [
            {
              title: "Role & Permission List",
              routeName: "roleList"
            },
            { title: "New", routeName: "roleNew" }
          ]
        }
      },
      {
        path: ":id/edit",
        name: "roleEdit",
        component: () => import("@/modules/role/entry/Edit.vue"),
        meta: {
          resource: "roles-and-permissions",
          action: "manage",
          layout: "default",
          auth: true,
          title: "Edit Role & Permission",
          breadcrumbs: [
            {
              title: "Role & Permission List",
              routeName: "roleList"
            },
            { title: "Edit", routeName: "roleEdit" }
          ]
        }
      }
    ]
  }
]

export default moduleRoutes
