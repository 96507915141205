export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello i18n !!"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မြန်မာ"])},
  "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "user": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Profile"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edit User ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No User found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading User data. Please wait."])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Role"])}
  },
  "patient": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Patient"])}
  },
  "doctor": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Doctor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Doctor"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Attendance"])}
  },
  "content": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Content"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Content"])}
  },
  "timeslot": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors'Time Slot"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors' Times Slot"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Timeslot"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Timeslot"])},
    "timeslot_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Slot"])}
  },
  "appointment": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment"])},
    "current_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Appointment"])},
    "appointment_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment History"])}
  },
  "corporate": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporates"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporates"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update Corporate ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No corporates found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading corporates data. Please wait."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate's Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "payment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Type"])}
  },
  "setting": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Setting"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update Setting ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No setting found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading setting data. Please wait."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Description"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Value"])}
  },
  "symptom": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symptoms"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symptoms"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Symptom"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update Symptom ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Symptom found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Symptom data. Please wait."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symptom EN"])},
    "name_mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symptom MM"])}
  },
  "chat": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat With Patients"])}
  },
  "specialities": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialities"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialities"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Specialities"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update Specialities ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Specialities found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Specialities data. Please wait."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialities Name"])}
  },
  "appointments": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointments"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointments List"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Appointment"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update Appointment ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Appointment found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Appointments data. Please wait."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Name"])}
  },
  "facility": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New facility"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update Facility ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No facility found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading facility data. Please wait."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Type"])}
  },
  "facilityType": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Type"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Type List"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Facility Type"])},
    "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update Facility Type ", _interpolate(_named("name"))])},
    "no_list_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No facility type found."])},
    "load_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading facility type data. Please wait."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Type Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Description"])}
  },
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "onlineOndemand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Ondemand"])},
  "onlineBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Booking"])},
  "Inperson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inperson"])},
  "videoChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Chat"])},
  "supportChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Chat"])},
  "timeSlotDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Slot Display"])},
  "consultationFeeOnlineOndemand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Ondemand Consultation Fee"])},
  "consultationFeeOnlineBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Booking Consultation Fee"])},
  "biography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biography"])},
  "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
  "keywordsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type and Search"])},
  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
  "where_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date Range"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "selected_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Selected"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name EN"])},
  "name_mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name MM"])},
  "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
  "mobile_country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Code"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "corporate_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Admin"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "today_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News of the day"])},
  "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["premium"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["share"])},
  "category": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])}
  },
  "role": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role & Permission"])}
  },
  "service": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])}
  }
}