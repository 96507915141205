const moduleRoutes = [
    {
        path: '/home-care',
        name: 'homeCare',
        children: [
            {
                path: '',
                name: 'homeCareList',
                component: () => import('@/modules/home_service/list/List.vue'),
                meta: {
                    resource: 'home-care',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Home Care',
                    breadcrumbs: [{ title: 'Home Care List', routeName: 'homeCareList' }]
                }
            },

            {
                path: ':id/edit',
                name: 'homeCareEdit',
                component: () => import('@/modules/home_service/entry/Edit.vue'),
                meta: {
                    resource: 'home-care',
                    action: 'edit',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Home Care',
                    breadcrumbs: [
                        { title: 'Home Care List', routeName: 'homeCareList' },
                        { title: 'Edit', routeName: 'homeCareEdit' }
                    ]
                }
            },

            {
                path: ':id/show',
                name: 'patientDetail',
                component: () => import('@/modules/patient/entry/Edit.vue'),
                meta: {
                    resource: 'home-care',
                    action: 'show',
                    layout: 'default',
                    auth: true,
                    title: 'Show Patient',
                    breadcrumbs: [
                        { title: 'Patient List', routeName: 'patientList' },
                        { title: 'Show', routeName: 'patientDetail' }
                    ]
                }
            }
        ]
    }
]

export default moduleRoutes
