const packagesaleRoutes = [
    {
        path: '/package-sale/corporate',
        name: 'corporate packages sale',
        children: [
            {
                path: '',
                name: 'corporatepackagesaleList',
                component: () => import('@/modules/package_sale/list/List.vue'),
                meta: {
                    resource: 'corporate-package-sale',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Corporate List',
                    breadcrumbs: [{ title: 'Corporate List', routeName: 'corporatepackagesaleList' }]
                }
            },
            {
                path: 'new',
                name: 'packagesaleNew',
                component: () => import('@/modules/package_sale/entry/New.vue'),
                meta: {
                    resource: 'corporate-package-sale',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New',
                    breadcrumbs: [
                        { title: 'Corporate List', routeName: 'corporatepackagesaleList' },
                        { title: 'New Package Sale', routeName: 'packagesaleNew' }
                    ]
                }
            },
            {
                path: ':id/corporate',
                name: 'packagecorporateView',
                component: () => import('@/modules/package_sale/view_package/CorporateView.vue'),
                meta: {
                    resource: 'corporate-package-sale',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Employee List',
                    breadcrumbs: [
                        { title: 'Corporate List', routeName: 'corporatepackagesaleList' },
                        { title: 'Package Sale List', routeName: 'packagecorporateList' },
                        { title: 'Employee List', routeName: 'packagecorporateView' }
                    ]
                }
            },
            {
                path: ':id',
                name: 'packagecorporateList',
                component: () => import('@/modules/package_sale/view_package/CorporatePackageView.vue'),
                meta: {
                    resource: 'corporate-package-sale',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Package Sale List',
                    breadcrumbs: [
                        { title: 'Corporate List', routeName: 'corporatepackagesaleList' },
                        { title: 'Package Sale List', routeName: 'packagecorporateList' }
                    ]
                }
            }
        ]
    },
    {
        path: '/package-sale/public',
        name: 'Public Package List',
        children: [
            {
                path: '',
                name: 'packagepublicView',
                component: () => import('@/modules/package_sale/view_package/PublicView.vue'),
                meta: {
                    resource: 'public-package-sale',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Public Package List',
                    breadcrumbs: [{ title: 'Public Package List', routeName: 'packagepublicView' }]
                }
            },
            {
                path: 'new',
                name: 'publicPackageNew',
                component: () => import('@/modules/package_sale/entry/Newpublicpackagesale.vue'),
                meta: {
                    resource: 'public-package-sale',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New',
                    breadcrumbs: [
                        { title: 'Public Package List', routeName: 'packagepublicView' },
                        { title: 'New Public Package Sale', routeName: 'publicPackageNew' }
                    ]
                }
            }
        ]
    }
]

export default packagesaleRoutes
