const doctorRoutes = [
  {
    path: "/doctor",
    name: "doctors",
    children: [
      {
        path: "",
        name: "doctorList",
        component: () => import("@/modules/doctor/list/List.vue"),
        meta: {
          resource: "doctors",
          action: "view",
          layout: "default",
          auth: true,
          title: "Doctors",
          breadcrumbs: [{ title: "Doctor List", routeName: "doctorList" }]
        }
      },
      {
        path: "new",
        name: "doctorNew",
        component: () => import("@/modules/doctor/entry/New.vue"),
        meta: {
          resource: "doctors",
          action: "create",
          layout: "default",
          auth: true,
          title: "New Doctor",
          breadcrumbs: [
            { title: "Doctor List", routeName: "doctorList" },
            { title: "New", routeName: "doctorNew" }
          ]
        }
      },
      {
        path: ":id/edit",
        name: "doctorEdit",
        component: () => import("@/modules/doctor/entry/Edit.vue"),
        meta: {
          resource: "doctors",
          action: "edit",
          layout: "default",
          auth: true,
          title: "Edit Doctor",
          breadcrumbs: [
            { title: "Doctor List", routeName: "doctorList" },
            { title: "Edit", routeName: "doctorEdit" }
          ]
        }
      }
    ]
  }
]

export default doctorRoutes
