const moduleRoutes = [
    {
        path: '/ads',
        name: 'ads',
        children: [
            {
                path: '',
                name: 'adsList',
                component: () => import('@/modules/ads/list/List.vue'),
                meta: {
                    resource: 'ads',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Ads',
                    breadcrumbs: [{ title: 'Ads List', routeName: 'adsList' }]
                }
            },
            {
                path: 'new',
                name: 'adsNew',
                component: () => import('@/modules/ads/entry/New.vue'),
                meta: {
                    resource: 'ads',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Ads',
                    breadcrumbs: [
                        { title: 'Ads List', routeName: 'adsList' },
                        { title: 'New', routeName: 'adsNew' }
                    ]
                }
            },
            {
                path: ':id/edit',
                name: 'adsEdit',
                component: () => import('@/modules/ads/entry/Edit.vue'),
                meta: {
                    resource: 'ads',
                    action: 'edit',
                    layout: 'default',
                    auth: true,
                    title: 'Edit ADS',
                    breadcrumbs: [
                        { title: 'Ads List', routeName: 'adsList' },
                        { title: 'Edit', routeName: 'adsEdit' }
                    ]
                }
            }
        ]
    }
]

export default moduleRoutes
