const moduleRoutes = [
  {
    path: "/doctor-timeslot",
    name: "timeslots",
    children: [
      {
        path: "",
        name: "timeSlotList",
        component: () => import("@/modules/timeslot/list/List.vue"),
        meta: {
          action: "view",
          resource: "doctor-timeslots",
          layout: "default",
          auth: true,
          title: "Time Slot",
          breadcrumbs: [{ title: "Time Slot List", routeName: "timeSlotList" }]
        }
      },
      {
        path: "/timeslotnew/:id?",
        name: "TimeSlotNew",
        component: () =>
          import("@/modules/timeslot/doctor_timeslot/entry/timeslotNew.vue"),
        meta: {
          action: "create",
          resource: "doctor-timeslots",
          layout: "default",
          auth: true,
          title: "New Time Slot",
          breadcrumbs: [
            { title: "Time Slot", routeName: "timeSlotList" },
            { title: "New", routeName: "doctorTimeSlotNew" }
          ]
        }
      },
      {
        path: ":id/edit",
        name: "TimeSlotEdit",
        component: () =>
          import("@/modules/timeslot/doctor_timeslot/entry/timeslotEdit.vue"),
        meta: {
          action: "edit",
          resource: "doctor-timeslots",
          layout: "default",
          auth: true,
          title: "Edit Time Slot",
          breadcrumbs: [
            { title: "Time Slot", routeName: "timeSlotList" },
            { title: "Edit", routeName: "doctorTimeSlotNew" }
          ]
        }
      },
      {
        path: ":id/list",
        name: "doctorTimeSlotList",
        component: () =>
          import("@/modules/timeslot/doctor_timeslot/list/DoctorTimeSlot.vue"),
        meta: {
          action: "view",
          resource: "doctor-timeslots",
          layout: "default",
          auth: true,
          title: "Doctor's Time Slot",
          breadcrumbs: [
            { title: "Doctors", routeName: "doctorList" },
            { title: "Doctor's Time Slot", routeName: "doctorTimeSlotList" }
          ]
        }
      },
      {
        path: "doctor/:id/new",
        name: "doctorTimeSlotNew",
        component: () =>
          import("@/modules/timeslot/doctor_timeslot/entry/timeslotNew.vue"),
        meta: {
          action: "create",
          resource: "doctor-timeslots",
          layout: "default",
          auth: true,
          title: "New Doctor's Time Slot",
          breadcrumbs: [
            { title: "Doctors", routeName: "doctorList" },
            { title: "Doctor's Time Slot", routeName: "doctorTimeSlotList" },
            { title: "New", routeName: "doctorTimeSlotNew" }
          ]
        }
      },
      {
        path: "doctor/:id/edit",
        name: "doctorTimeSlotEdit",
        component: () =>
          import("@/modules/timeslot/doctor_timeslot/entry/timeslotEdit.vue"),
        meta: {
          action: "edit",
          resource: "doctor-timeslots",
          layout: "default",
          auth: true,
          title: "Edit Doctor's Time Slot",
          breadcrumbs: [
            { title: "Doctors", routeName: "doctorList" },
            { title: "Doctor's Time Slot", routeName: "doctorTimeSlotList" },
            { title: "Edit", routeName: "doctorTimeSlotNew" }
          ]
        }
      }
    ]
  }
]

export default moduleRoutes
