<template>
    <ul v-if="items">
        <template v-for="(item, i) of items">
            <li
                v-if="visible(item) && !item.separator"
                :key="item.label || i"
                :class="[
                    {
                        'layout-menuitem-category': root,
                        'active-menuitem': activeIndex === i && !item.to && !item.disabled
                    }
                ]"
                role="none"
            >
                <template v-if="root">
                    <div v-if="item.label" class="layout-menuitem-root-text white-space-nowrap overflow-hidden text-overflow-ellipsis" :aria-label="item.label">
                        {{ $t(`${item.label}`) }}
                    </div>
                    <appsubmenu :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)" />
                </template>
                <template v-else>
                    <router-link
                        v-if="item.to"
                        v-ripple
                        :to="item.to"
                        :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                        :style="item.style"
                        :target="item.target"
                        :aria-label="item.label"
                        active-class="router-link-active"
                        exact-active-class="router-link-exact-active"
                        role="menuitem"
                        @click="onMenuItemClick($event, item, i)"
                    >
                        <i v-badge.danger v-if="item.resource == 'doctor-chat' && store.getChatCount != 0" :class="item.icon" class="badge-center" />
                        <i v-else :class="item.icon" />
                        <span class="white-space-nowrap overflow-hidden text-overflow-ellipsis">{{ $t(`${item.label}`) }}</span>
                        <i v-if="item.items" :class="activeIndex === i ? 'ri-arrow-up-s-line ri-lg menuitem-toggle-icon' : 'ri-arrow-down-s-line ri-lg menuitem-toggle-icon'" />
                        <Badge v-if="item.badge" :value="item.badge" />
                    </router-link>

                    <a
                        v-if="!item.to"
                        v-ripple
                        :href="item.url || '#'"
                        :style="item.style"
                        :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                        :target="item.target"
                        :aria-label="item.label"
                        role="menuitem"
                        @click="onMenuItemClick($event, item, i)"
                    >
                        <i :class="item.icon" />

                        <span class="white-space-nowrap overflow-hidden text-overflow-ellipsis">{{ $t(`${item.label}`) }}</span>
                        <i v-if="item.items" :class="activeIndex === i ? 'ri-arrow-up-s-line ri-lg menuitem-toggle-icon' : 'ri-arrow-down-s-line ri-lg menuitem-toggle-icon'" />
                        <Badge v-if="item.badge" :value="item.badge" />
                    </a>

                    <transition name="layout-submenu-wrapper">
                        <appsubmenu v-show="activeIndex === i" :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)" />
                    </transition>
                </template>
            </li>
            <li v-if="visible(item) && item.separator" :key="'separator' + i" class="p-menu-separator" :style="item.style" role="separator" />
        </template>
    </ul>
</template>
<script>
import Badge from 'primevue/badge'
import ability from '@/libs/acl/ability'
// import { db } from '@/libs/firebase'
import { useChatCountStore } from '@/store/chatCountStore'

export default {
    name: 'Appsubmenu',
    components: { Badge },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        items: Array,
        root: {
            type: Boolean,
            default: false
        }
    },
    emits: ['menuitem-click'],
    data() {
        return {
            activeIndex: null,
            user: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : 'undefined',
            // currentPath: this.$route.path

            chatCount: 0,
            store: useChatCountStore()
        }
    },
    mounted() {},
    methods: {
        // showChatCount: function () {
        //     db.collection('chat_queues')
        //         .where('status', '==', 'pending')
        //         .onSnapshot(
        //             (onSnapshot) => {
        //                 if (onSnapshot.size > 0) {
        //                     this.supportChatToast({
        //                         severity: 'info',
        //                         summary: 'Go to Chat',
        //                         detail: 'You have ' + onSnapshot.size + ' chat notification !'
        //                     })
        //                     this.newSupportChat(this.user.id)
        //                     this.chatCount = onSnapshot.size
        //                 } else {
        //                     this.chatCount = 0
        //                 }
        //             },
        //             (err) => {
        //                 console.log(`Encountered error: ${err}`)
        //                 this.chatCount = 0
        //             }
        //         )
        // },
        onMenuItemClick(event, item, index) {
            if (item.disabled) {
                event.preventDefault()
                return
            }

            if (!item.to && !item.url) {
                event.preventDefault()
            }

            //execute command
            if (item.command) {
                item.command({ originalEvent: event, item: item })
            }

            this.activeIndex = index === this.activeIndex ? null : index

            this.$emit('menuitem-click', {
                originalEvent: event,
                item: item
            })
        },
        visible(item) {
            if (item.resource == 'doctor-chat') {
                if (this.user !== 'undefined' && this.user.role == 'doctor' && !this.user.doctor.support_chat_enable) {
                    return false
                }
            }
            if (item.resource) {
                return ability.can(item.action || 'index', item.resource)
            }
            return true
        }
        // childRouteExist(items) {
        //   let isExist = false
        //   if (items) {
        //     for (let i = 0, length = items.length; i < length; i++) {
        //       if (items[i].to == this.currentPath) {
        //         isExist = true
        //         break
        //       }
        //     }
        //   }
        //   return isExist
        // }
    }
}
</script>
