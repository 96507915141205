import api from "@/libs/axios"

const endpoint = "/communication/chat-list"

const service = {
  getAppointmentChatUser: async (params) => {
    try {
      const result = await api.get(endpoint, {
        params: params
      })
      return result.data
    } catch {
      return null
    }
  }
}

export { service }
