const packageusageRoutes = [
    {
        path: '/package/usage',
        name: 'package usage',
        children: [
            {
                path: '',
                name: 'packageusageList',
                component: () => import('@/modules/package_usage/list/List.vue'),
                meta: {
                    resource: 'package-usage',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Package Usage List',
                    breadcrumbs: [{ title: 'Package Usage List', routeName: 'packageusageList' }]
                }
            },
            {
                path: 'new',
                name: 'packageusageNew',
                component: () => import('@/modules/package_usage/entry/New.vue'),
                meta: {
                    resource: 'package-usage',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Service Usage',
                    breadcrumbs: [
                        { title: 'Package Usage List', routeName: 'packageusageList' },
                        { title: 'New', routeName: 'packageusageNew' }
                    ]
                }
            }
        ]
    }
]

export default packageusageRoutes
