import CryptoJS from 'crypto-js';
import unserialize from './unserialize';

export default class Encrypter {
    /**
     * Encrypter constructor.
     *
     * @param {string} key
     */
    constructor(key) {
        if (key.startsWith('base64:')) {
            key = key.substr(7);
        }
        this.key = key;
    }

    /**
     * Decrypt string.
     *
     * @param {string} data
     * @param {boolean} convert
     */
    decrypt(data, convert = true) {
        var params = JSON.parse(atob(data));
        var decrypted = CryptoJS.AES.decrypt(params.value, CryptoJS.enc.Base64.parse(this.key), {
            iv: CryptoJS.enc.Base64.parse(params.iv)
        }).toString(CryptoJS.enc.Utf8);

        return convert ? unserialize(decrypted) : decrypted;
    }
}