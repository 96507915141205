import { onMounted, reactive, ref, onBeforeUnmount } from 'vue'
import { useContentStore } from '@/modules/content/store'
import { useStore as useCategoryStore } from '@/modules/category/store'
import { required } from '@vuelidate/validators'
import { useRoute, useRouter } from 'vue-router'
import EventBus from '@/libs/AppEventBus'
import moment from 'moment'
import { useConfirm } from 'primevue/useconfirm'
import { Errors } from '@/utils/server-validation'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { dateShort } from '@/utils/formatter'
import { useVuelidate } from '@vuelidate/core'

export const useEditContent = () => {
    const contentStore = useContentStore()
    const categoryStore = useCategoryStore()
    const route = useRoute()
    const router = useRouter()
    const confirm = useConfirm()
    const photoURL = ref('')
    const isLoading = ref(false)
    const submitted = ref(false)
    const errors = new Errors()
    const showCropDialog = ref(false)
    const crop_photo = ref(null)
    const photo = ref(null)
    const fileUrl = ref('')
    const optionsCategory = ref([])
    const imgErrorMessage = ref(null)

    const state = reactive({
        titleEn: '',
        titleMm: '',
        title: '',
        status: '',
        photo: '',
        category_id: null,
        user_type: '',
        post_type: '',
        contentEn: '',
        contentMm: '',
        is_notifiable: false,
        has_notified: false,
        published_at: '',
        video_url: null,
        expired_at: ''
    })

    const formError = reactive({
        titleEn: '',
        titleMm: ''
    })

    const rules = {
        title: { required },
        titleEn: { required },
        titleMm: { required },
        post_type: { required },
        user_type: { required },
        expired_at: {},
        category_id: { required },
        photo: { required },
        filename: {},
        onlineOndemand: {},
        active: {},
        emr_speciality_id: { required },
        video_url: {}
    }

    const v$ = useVuelidate(rules, state)

    const optionsUserType = ref([
        { name: 'COPORATE', code: 'corporate' },
        { name: 'PUBLIC', code: 'public' }
    ])

    const optionsPostType = ref([
        { name: 'PREMIUM', code: 'premium' },
        { name: 'FREE', code: 'free' }
    ])

    onMounted(() => {
        editContent()
        categoryData()
    })

    onBeforeUnmount(() => {
        contentStore.$dispose()
        categoryStore.$dispose()
    })

    const categoryData = async () => {
        isLoading.value = true

        try {
            await categoryStore.fetchAllContentCategory({
                page: 1,
                per_page: 'all',
                active: 1,
                type: 'health_news'
            })

            const response = categoryStore.listContentResponse

            if (response) {
                optionsCategory.value = JSON.parse(JSON.stringify(response.data))
            }

            isLoading.value = false
        } catch (error) {
            isLoading.value = false
            if (error.status === 422) {
                const err = error.data.data
                errors.record(err)
            }
        }
    }

    const editContent = async () => {
        isLoading.value = true

        await contentStore.editContentAction({
            id: route.params.id
        })

        const response = contentStore.getEditContent

        if (response) {
            ;(state.id = route.params.id), (state.name = response.data.content.name)

            state.titleEn = JSON.parse(response.data.content.title).en
            state.titleMm = JSON.parse(response.data.content.title).mm
            state.contentEn = response.data.content.body ? JSON.parse(response.data.content.body).en : ''
            state.contentMm = response.data.content.body ? JSON.parse(response.data.content.body).mm : ''
            state.status = response.data.content.status
            state.user_type = response.data.content.user_type.toLowerCase()
            state.post_type = response.data.content.post_type.toLowerCase()
            state.category_id = response.data.content.category.active == 0 ? '' : response.data.content.category_id
            photoURL.value = response.data.content.photo
            state.is_notifiable = response.data.content.is_notifiable == 1 ? true : false
            state.published_at = response.data.content.published_at ? dateShort(response.data.content.published_at) : null
            state.expired_at = response.data.content.expired_at ? dateShort(response.data.content.expired_at) : ''
            state.video_url = response.data.content.video_url
        }

        isLoading.value = false
    }

    const handleSubmit = (isFormValid) => {
        submitted.value = true

        if (!isFormValid || imgErrorMessage.value) {
            return
        }
        updateContent()
    }

    const onClickPublish = () => {
        if (imgErrorMessage.value) {
            return
        }
        updateContent('published')
    }

    const onClickUnpublish = () => {
        updateContent('unpublished')
    }

    const onClickDraft = () => {
        if (imgErrorMessage.value) {
            return
        }
        updateContent('drafted')
    }

    const updateContent = async (status = null) => {
        errors.clear()
        formError.titleEn = ''
        formError.titleMm = ''
        isLoading.value = true

        const content = JSON.stringify({
            en: state.contentEn,
            mm: state.contentMm
        })

        let title = JSON.stringify({
            en: state.titleEn,
            mm: state.titleMm
        })

        if (state.titleEn === '') {
            formError.titleEn = `title (english) is required`
            title = ''
        }

        if (state.titleMm === '') {
            formError.titleMm = `title (myanmar) is required`
            title = ''
        }

        const publishedDate = new Date(state.published_at)
        const currentDate = new Date()
        var formData = new FormData()
        formData.append('id', route.params.id)

        if (state.photo) {
            formData.append('photo', state.photo)
        }

        switch (status) {
            case 'published':
                state.status = publishedDate > currentDate ? 'pending' : 'published'
                break
            case 'drafted':
                state.status = 'drafted'
                break
            case 'unpublished':
                state.status = 'unpublished'
                break

            default:
                break
        }

        formData.append('title', title)
        formData.append('body', content)
        formData.append('category_id', state.category_id)
        formData.append('status', state.status.toLowerCase())
        formData.append('published_at', state.published_at === null ? null : moment(state.published_at).format('YYYY-MM-DD'))

        formData.append('expired_at', state.expired_at === null || state.expired_at === '' ? '' : moment(state.expired_at).format('YYYY-MM-DD'))

        formData.append('is_notifiable', state.is_notifiable ? 1 : 0)
        formData.append('post_type', state.post_type)
        formData.append('user_type', state.user_type)
        formData.append('_method', 'POST')
        if (state.video_url != null) formData.append('video_url', state.video_url)

        try {
            await contentStore.updateContentAction(formData)

            const response = contentStore.getUpdateContent

            if (response) {
                router.push({ name: 'contentList' })
                EventBus.emit('show-toast', {
                    severity: 'success',
                    summary: '',
                    detail: response.message
                })
            }

            isLoading.value = false
        } catch (error) {
            isLoading.value = false
            if (error.status === 422) {
                const err = error.data.data
                errors.record(err)
            }
        }
    }

    const onUpload = (event) => {
        photoURL.value = event.files[0].objectURL
        state.photo = event.files[0]
        state.filename = event.files[0].filename

        if (state.photo) {
            const reader = new FileReader()
            reader.onload = (e) => {
                validateImageRatio(e.target.result)
            }
            reader.readAsDataURL(state.photo)
        }
    }

    const validateImageRatio = (image) => {
        const img = new window.Image()
        img.onload = () => {
            const width = img.width
            const height = img.height
            const desiredRatio = 2
            const ratio = parseInt(width / height)

            if (ratio !== desiredRatio) {
                imgErrorMessage.value = `The image ratio should be ${desiredRatio}:1. Please upload a valid image.`
            } else {
                imgErrorMessage.value = null
            }
        }
        img.src = image
    }

    const takePicture = async () => {
        const cameraOptions = {
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.Uri,
            source: CameraSource.Photos,
            saveToGallery: false,
            width: 300,
            height: 300
        }

        const image = await Camera.getPhoto(cameraOptions)

        try {
            // image.webPath will contain a path that can be set as an image src.
            // You can access the original file using image.path, which can be
            // passed to the Filesystem API to read the raw data of the image,
            // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
            photo.value = image.webPath
            showCropDialog.value = true
        } catch (err) {
            console.log(err.message)
        }
    }

    const cropImage = async ({ canvas }) => {
        // const result = this.$refs.cropper.getResult()
        // const newTab = window.open()
        // // for testing open the result in a new tab
        // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
        //   "image/jpeg"
        // )}"></img>`

        crop_photo.value = canvas.toDataURL('image/jpeg')
        canvas.toBlob(function (blob) {
            fileUrl.value = blob
        })
    }

    const showConfirmDialog = (id) => {
        confirm.require({
            message: 'Are you sure?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Yes, delete it',
            rejectLabel: 'Cancel',
            acceptClass: 'p-button-danger',
            rejectClass: 'p-button-danger p-button-text',
            accept: () => {
                deleteContent(id)
            },
            reject: () => {
                //callback to execute when user rejects the action
            },
            onHide: () => {
                //Callback to execute when dialog is hidden
            }
        })
    }

    const deleteContent = async (id) => {
        isLoading.value = true
        await contentStore.deleteContentAction({ id: id })

        const response = contentStore.getDeleteContent

        if (response) {
            router.push({ name: 'contentList' })
            EventBus.emit('show-toast', {
                severity: 'success',
                summary: '',
                detail: response.message
            })
        }

        isLoading.value = false
    }

    return {
        formError,
        isLoading,
        onUpload,
        state,
        onClickPublish,
        onClickUnpublish,
        submitted,
        errors,
        handleSubmit,
        optionsUserType,
        optionsCategory,
        showConfirmDialog,
        crop_photo,
        cropImage,
        showCropDialog,
        takePicture,
        optionsPostType,
        photo,
        photoURL,
        v$,
        onClickDraft,
        imgErrorMessage
    }
}
