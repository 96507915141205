const moduleRoutes = [
  {
    path: "/speciality",
    name: "specialities",
    children: [
      {
        path: "",
        name: "specialitiesList",
        component: () => import("@/modules/specialities/list/List.vue"),
        meta: {
          resource: "specialities",
          action: "view",
          layout: "default",
          auth: true,
          title: "Specialities",
          breadcrumbs: [
            { title: "Specialities List", routeName: "specialitiesList" }
          ]
        }
      },
      {
        path: "new",
        name: "specialitiesNew",
        component: () => import("@/modules/specialities/entry/New.vue"),
        meta: {
          resource: "specialities",
          action: "create",
          layout: "default",
          auth: true,
          title: "New Speciality",
          breadcrumbs: [
            { title: "Specialities List", routeName: "specialitiesList" },
            { title: "New", routeName: "specialitiesNew" }
          ]
        }
      },
      {
        path: ":id/edit",
        name: "specialitiesEdit",
        component: () => import("@/modules/specialities/entry/Edit.vue"),
        meta: {
          resource: "specialities",
          action: "edit",
          layout: "default",
          auth: true,
          title: "Edit Speciality",
          breadcrumbs: [
            { title: "Specialities List", routeName: "specialitiesList" },
            { title: "Edit", routeName: "specialitiesEdit" }
          ]
        }
      }
    ]
  }
]

export default moduleRoutes
