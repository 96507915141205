import { defineStore } from 'pinia'
import { authService } from './authService'
// import { Ability } from "@casl/ability"
import { initialAbility } from '@/libs/acl/config'
import ability from '@/libs/acl/ability'
import EventBus from '@/libs/AppEventBus'
// import Cookies from 'js-cookie'

export const useAuthStore = defineStore({
    id: 'useAuthStore',
    state: () => ({
        token: localStorage.getItem('userToken') || null,
        healthnewsToken: localStorage.getItem('healthnewsToken') || null,
        userData: localStorage.getItem('userData') || null,
        userPermission: localStorage.getItem('userPermission') || null,
        loginResponse: null,
        changePasswordResponse: null,
        forgotPasswordResponse: null,
        firebaseCredential: null,
        requestOTPResponse: null,
        otpVerifyResponse: null,
        checkRoleResponse: null
    }),

    getters: {
        getToken(state) {
            return state.token
        },
        getHealthnewsToken(state) {
            return state.healthnewsToken
        },
        getUserData(state) {
            return state.userData ? JSON.parse(state.userData) : null
        },
        getUserPermission(state) {
            return state.userPermission
        },
        getLoginResponse(state) {
            return state.loginResponse
        },
        isAuth(state) {
            return state.token ? true : false
        },
        getChangePasswordResponse(state) {
            return state.changePasswordResponse
        },
        getForgotPasswordResponse(state) {
            return state.forgotPasswordResponse
        },
        isHealthnewsAuth(state) {
            return state.healthnewsToken ? true : false
        },
        getFirebaseCredential(state) {
            return state.firebaseCredential
        },
        getRequestOTPResponse(state) {
            return state.requestOTPResponse
        },
        getOtpVerifyResponse(state) {
            return state.otpVerifyResponse
        },
        getCheckRoleResponse(state) {
            return state.checkRoleResponse
        }
    },

    actions: {
        async login(params) {
            const response = await authService.login(params)
            this.loginResponse = response
            if (response) {
                if (response.data) {
                    this.token = response.data.access_token
                    if (response.data.user.password_changed_at) {
                        this.setLoginUser(response)
                    }
                } else {
                    EventBus.emit('show-toast', {
                        severity: 'error',
                        summary: '',
                        detail: response.message
                    })
                }
            }
        },
        logout() {
            this.token = null
            this.userData = null
            authService.logout()
        },
        async changePassword(params) {
            const response = await authService.changePassword(params)
            this.changePasswordResponse = response
        },
        setLoginUser(response) {
            this.userData = JSON.stringify(response.data.user)
            var responseAbility = response.data.allowed_permissions
            var currentInitialAbility = initialAbility
            var userAbility = responseAbility.concat(currentInitialAbility)
            var encodeUserAbility = JSON.stringify(userAbility)
            this.userPermission = encodeUserAbility
            localStorage.setItem('userPermission', encodeUserAbility)
            //update abality
            ability.update(JSON.parse(encodeUserAbility))

            localStorage.setItem('userToken', response.data.access_token)
            localStorage.setItem('userData', JSON.stringify(response.data.user))
            localStorage.setItem('base64_path', 'base64:alhuMnI1dTh4L0E1RChHK0thUGRTZ1ZrWXAzczZ2OXk=')
        },
        async forgotPassword(params) {
            const response = await authService.forgotPassword(params)
            this.forgotPasswordResponse = response
        },
        async fetchFirebaseCredential() {
            const response = await authService.getFirebaseCredential()
            this.firebaseCredential = response
        },
        async requestOTP(params) {
            const response = await authService.requestOTP(params)
            this.requestOTPResponse = response
        },
        async checkRole(params) {
            const response = await authService.checkRole(params)
            this.checkRoleResponse = response
        },
        async otpVerify(params) {
            const response = await authService.otpVerify(params)
            if (response) {
                if (response.data.user.role === 'patient') {
                    this.otpVerifyResponse = response
                } else if (response.data.user.role !== 'patient') {
                    this.token = response.data.access_token
                    this.setLoginUser(response)
                    this.otpVerifyResponse = response
                }
            } else {
                this.otpVerifyResponse = false
            }
        },
        setUserData(data) {
            this.userData = data
        },
        async revokeCurrentJwtToken() {
            await authService.revokeCurrentJwtToken()
        }
    }
})
