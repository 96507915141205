const moduleRoutes = [
    {
        path: '/patient',
        name: 'patients',
        children: [
            {
                path: '',
                name: 'patientList',
                component: () => import('@/modules/patient/list/List.vue'),
                meta: {
                    resource: 'patients',
                    action: 'view',
                    layout: 'default',
                    auth: true,
                    title: 'Patients',
                    breadcrumbs: [{ title: 'Patient List', routeName: 'patientList' }]
                }
            },

            {
                path: 'new',
                name: 'patientNew',
                component: () => import('@/modules/patient/entry/New.vue'),
                meta: {
                    resource: 'patients',
                    action: 'create',
                    layout: 'default',
                    auth: true,
                    title: 'New Patient',
                    breadcrumbs: [
                        { title: 'Patient List', routeName: 'patientList' },
                        { title: 'New', routeName: 'patientNew' }
                    ]
                }
            },
            {
                path: ':id/edit',
                name: 'patientEdit',
                component: () => import('@/modules/patient/entry/Edit.vue'),
                meta: {
                    resource: 'patients',
                    action: 'edit',
                    layout: 'default',
                    auth: true,
                    title: 'Edit Patient',
                    breadcrumbs: [
                        { title: 'Patient List', routeName: 'patientList' },
                        { title: 'Edit', routeName: 'patientEdit' }
                    ]
                }
            },
            {
                path: ':id/show',
                name: 'patientDetail',
                component: () => import('@/modules/patient/entry/Edit.vue'),
                meta: {
                    resource: 'patients',
                    action: 'show',
                    layout: 'default',
                    auth: true,
                    title: 'Show Patient',
                    breadcrumbs: [
                        { title: 'Patient List', routeName: 'patientList' },
                        { title: 'Show', routeName: 'patientDetail' }
                    ]
                }
            }
        ]
    },
    {
        path: '/doctor_patient/list',
        name: 'doctorpatientList',
        component: () => import('@/modules/patient/doctor_patient/list/List.vue'),
        meta: {
            resource: 'patients',
            action: 'view',
            layout: 'default',
            auth: true,
            title: 'Patients',
            breadcrumbs: [{ title: 'Patient List', routeName: 'doctorpatientList' }]
        }
    },
    {
        path: '/registration-request',
        name: 'requestList',
        component: () => import('@/modules/patient/registration_request/list/List.vue'),
        meta: {
            resource: 'registration-requests',
            action: 'view',
            layout: 'default',
            auth: true,
            title: 'Registration Request',
            breadcrumbs: [{ title: 'Registration Request List', routeName: 'requestList' }]
        }
    }
]

export default moduleRoutes
