import { reactive, ref, onMounted, watch, computed } from 'vue'
import { useContentStore } from '@/modules/content/store'
import { useDebounceFn } from '@/utils/debounce'
import { multisortConvert } from '@/utils/multisort'
import { dateShort, utcToLocal, dateformat } from '@/utils/formatter'
import moment from 'moment'

export const useContentList = () => {
    const loading = ref(true)
    const store = useContentStore()
    const dt = ref()
    const lazyParams = ref({})
    const contentList = ref([])
    const totalRecords = ref(10)
    const search = ref('')
    const selectedStatus = ref(null)
    const dateBetween = ref('')
    const menu = ref()

    const columns_arr = [
        // { field: "title.en", header: "Title (English)", width: "150px" },
        // { field: "title.mm", header: "Title (Myanmar)", width: "150px" },
    ]

    const statuses = ref([
        { name: 'PENDING', code: 'pending' },
        { name: 'PUBLISHED', code: 'published' },
        { name: 'UNPUBLISHED', code: 'unpublished' },
        { name: 'DRAFTED', code: 'drafted' }
    ])

    const state = reactive({
        date_from: '',
        date_to: ''
    })

    onMounted(() => {
        resetPagination()
        fetchContentList()
    })

    const resetSearch = () => {
        resetPagination()
        dateBetween.value = ''
        search.value = ''
        selectedStatus.value = null
    }

    const fetchContentList = async () => {
        loading.value = true

        const dateBetweenformat = []

        if (dateBetween.value) {
            if (dateBetween.value[0]) state.date_from = dateformat(utcToLocal(dateBetween.value[0]))
            if (dateBetween.value[1]) {
                state.date_to = dateformat(utcToLocal(dateBetween.value[1]))
            } else {
                state.date_to = state.date_from
            }

            dateBetweenformat[0] = state.date_from
            dateBetweenformat[1] = state.date_to
        }
        await store.listContentAction({
            page: (lazyParams.value.page += 1),
            per_page: lazyParams.value.rows,
            order: multisortConvert(lazyParams.value.multiSortMeta),
            search: search.value,
            published_date: dateBetweenformat,
            status: selectedStatus.value,
            is_portal: 1
        })

        const response = store.getAllContent

        if (response) {
            contentList.value = response.data.data
            totalRecords.value = response.data.total
            contentList.value.map((content) => {
                content.title = JSON.parse(content.title)
                content.body = JSON.parse(content.body)
                content.total_reactions = JSON.parse(content.total_reactions)
                content.published_at = dateShort(moment(content.published_at).format('YYYY-MM-DD'))
                content.status = content.expired_status == true ? 'EXPIRED' : content.status
                switch (content.status) {
                    case 'PUBLISHED':
                        content.severity = 'background-color:#6ebe71'
                        break
                    case 'DFRATED':
                        content.severity = 'background-color:#f9ae61'
                        break
                    case 'PENDING':
                        content.severity = 'background-color:#fccc55'
                        break
                    case 'UNPUBLISHED':
                        content.severity = 'background-color:#a5326b'
                        break
                    case 'EXPIRED':
                        content.severity = 'background-color:#ff4032'
                        break

                    default:
                        break
                }

                return content
            })
        }

        loading.value = false
    }

    const actionItems = ref([
        {
            label: 'Export',
            icon: 'pi pi-download',
            command: () => {
                dt.value.exportCSV()
            }
        }
    ])

    const columnMenu = ref()

    const columns = ref([
        {
            field: 'title.en',
            header: 'Title (english)',
            sortable: false,
            selected: true,
            style: 'min-width: 20rem',
            frozen: true
        },
        {
            field: 'title.mm',
            header: 'Title (myanmar)',
            sortable: true,
            selected: true,
            style: 'min-width: 18rem'
        },
        {
            field: 'photo',
            header: 'Photo',
            sortable: true,
            selected: true,
            style: 'min-width: 14rem'
        },
        { field: 'view_count', header: 'View Count', style: 'min-width: 10rem', selected: true },
        { field: 'user.name', header: 'Created By', style: 'min-width: 10rem', selected: true },
        { field: 'post_type', header: 'Post Type', style: 'min-width: 6rem', selected: true },
        {
            field: 'published_at',
            header: 'Post Created Date',
            sortable: true,
            style: 'min-width: 15rem',
            selected: true
        },
        {
            field: 'status',
            header: 'Status',
            sortable: true,
            selected: true,
            style: 'min-width: 10rem'
        },
        {
            field: 'action',
            header: 'Action',
            sortable: false,
            selected: true,
            style: 'min-width: 5rem; max-width: 5rem',
            frozen: true,
            alignFrozen: 'right'
        }
    ])

    const resetPagination = () => {
        lazyParams.value = {
            page: 0,
            rows: dt.value.rows,
            multiSortMeta: []
        }
    }

    const toggleMenu = (event) => {
        menu.value.toggle(event)
    }

    const onPage = (event) => {
        lazyParams.value = event
        lazyParams.value.multiSortMeta = []
        fetchContentList()
    }

    const onSort = (event) => {
        lazyParams.value = event
        lazyParams.value.page = 0
        fetchContentList()
    }
    const toggleColumnMenu = (event) => {
        columnMenu.value.toggle(event)
    }
    const selectedColumns = computed(() => {
        return columns.value.filter((col) => col.selected)
    })

    watch([selectedStatus, dateBetween], () => {
        lazyParams.value.page = 0
        fetchContentList()
    })

    watch(
        [search],
        useDebounceFn(() => {
            resetPagination()
            fetchContentList()
        }, 500)
    )

    return {
        dt,
        toggleMenu,
        menu,
        lazyParams,
        actionItems,
        totalRecords,
        contentList,
        loading,
        resetSearch,
        selectedStatus,
        store,
        dateBetween,
        statuses,
        search,
        columns_arr,
        onPage,
        onSort,
        selectedColumns,
        columns,
        columnMenu,
        toggleColumnMenu
    }
}
